import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { SyncLoader } from "react-spinners";
import {
    Button,
    Checkbox,
    CircularProgress,
    LinearProgress,
    MenuItem,
    Select,
    Tab,
    TableHead,
    TableSortLabel,
    Tabs,
    Typography,
} from "@mui/material";

import Iconify from "../../utilities/iconnify";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};
function stableSort(array = [], comparator) {
    if (!array.length) return [];
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
const EnhancedTableHead = ({
    onSelectAllClick,
    selected,
    data,
    headersData,
    order,
    orderBy,
    setOrderBy,
    onRequestSort,
    enableSelection,
}) => {
    const createSortHandler = (property) => (event) => {
        setOrderBy(property)
        onRequestSort(event, property);
    };

    const setHeaders = (headers) => {
        return headers.map((headCell, index) => (
            <TableCell
                key={index}
                align={headCell.numeric ? "center" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.name ? order : false}
                size="small"
            >
                <TableSortLabel
                    active={orderBy === headCell.name}
                    direction={orderBy === headCell.name ? order : "asc"}
                    onClick={(event) => {
                        // setPage(1);
                        createSortHandler(headCell.name)(event);
                    }}
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "1rem",
                        fontWeight: "1rem"
                    }}
                >
                    {headCell.label}
                    {orderBy === headCell.name ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        ));
    };

    return (
        <TableHead sx={{ backgroundColor: "#c4af6c" }}>
            <TableRow>
                {enableSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={
                                selected?.length > 0 && selected?.length < data?.length
                            }
                            checked={data?.length > 0 && selected?.length === data?.length}
                            onChange={onSelectAllClick}
                            inputProps={{
                                "aria-label": "select all names",
                            }}
                        />
                    </TableCell>
                )}
                {setHeaders(headersData)}
                {/* {headersData ? setHeaders(headersData) : setHeaders(headCells)} */}
            </TableRow>
        </TableHead>
    );
};
const DataTable = ({
    data,
    headers,
    isLoading = false,
    onClickRow,
    fetcher,
    enableSection = false,
    children,
    filtros = null,
    mensaje = "No hay propiedades disponibles",
    currentPage = 1,
    setCurrentPage,
    properties,
    orderTableBy,
    orderTable,
    openAccordion,
    accordionData,
}) => {
    const [page, setPage] = useState(data.meta?.current_page ?? 1);
    const [selected, setSelected] = useState([]);
    const [filteredData, setFilteredData] = useState(data.rows);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [isArrowDown, setIsArrowDown] = useState(false);
    const [order, setOrder] = useState(orderTable || "");
    const [orderBy, setOrderBy] = useState(orderTableBy || "");
    const [expandedRow, setExpandedRow] = useState(null);


    // console.log(data);
    const handleStatusClick = () => {
        setIsArrowDown((prevIsArrowDown) => !prevIsArrowDown);

        const newData = isArrowDown
            ? data?.filter((row) => row.status === "0")
            : data?.filter((row) => row.status === "1");

        setFilteredData(newData);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // Eliminar referencia a userIdentifier
            setSelected(data.map((n) => n.id)); // Suponiendo que "id" sea un identificador adecuado
            return;
        }
        setSelected([]);
    };
    const handleNext = () => {
        setPage((page) => page + 1);
    };

    const handleBack = () => setPage((page) => (page === 1 ? page : page - 1));

    const handleRequestSort = (event, property) => {
        console.log(property);
        setPage(1);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () => stableSort(data.rows, getComparator(order, orderBy)),
        // console.log(orderBy),
        [data.rows]
    );
    useEffect(() => {
        if (fetcher) {
            fetcher({ offset: page, limit: rowsPerPage, filtros: filtros, orderBy: orderBy, order: order });
            console.log({ offset: page, limit: rowsPerPage, filtros: filtros, orderBy: orderBy, order: order });
        }
        if (setCurrentPage) {
            setCurrentPage(page);
        }
    }, [page, rowsPerPage, order, orderBy, filtros]);


    const isLastPage = !data?.meta?.has_more_pages;
    return (
        <Box sx={{ width: "100%", minHeight: "100vh" }}>
            <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
                {/* PAGINACION */}
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                    gap: 3,
                    alignItems: "center",
                }}
                >
                    <Box className="lae-flex lae-flex-row" sx={{ alignItems: "center" }}>
                        <Typography sx={{
                            fontSize: '0.9em',
                        }}
                        >Tamaño de página:</Typography>
                        <Select
                            value={rowsPerPage}
                            className="selectPaginacion"
                            label="Age"
                            size="small"
                            variant="standard"
                            onChange={({ target }) => {
                                setPage(1);
                                setRowsPerPage(target.value);
                            }}
                        >
                            {[50, 100, 150, 200, 250, 300].map((option, index) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box className="lae-flex lae-flex-row">
                        <Typography sx={{
                            fontSize: '0.9em',
                        }}
                        >
                            Nº Registros: {isLastPage ? data.meta?.total : data.rows.length * page} de{" "}
                            {data.meta?.total ?? 0}
                        </Typography>
                    </Box>
                    <Box className="lae-flex lae-flex-row" sx={{ gap: 0 }}>
                        <Button variant="text" onClick={handleBack} disabled={page === 1}>
                            <Iconify
                                icon="material-symbols:chevron-left"
                                sx={{
                                    color: page === 1 ? "gray" : "black",
                                    height: 24,
                                    width: 24,
                                }}
                            />
                        </Button>
                        <Button variant="text" onClick={handleNext} disabled={isLastPage}>
                            <Iconify
                                icon="material-symbols:chevron-right"
                                sx={{
                                    color: isLastPage ? "gray" : "black",
                                    height: 24,
                                    width: 24,
                                }}
                            />
                        </Button>
                    </Box>
                </Box>
                <TableContainer>
                    {children}
                    {isLoading &&
                        <LinearProgress
                            sx={{
                                width: "100%",
                                backgroundColor: '#33383b', // Color de fondo original
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: 'rgba(196, 175, 108, 0.9)',
                                },
                            }}
                        />
                    }
                    <Table
                        sx={{ minWidth: 750,fontSize:22}}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        {/* Componente TABLEHEAD */}
                        <EnhancedTableHead
                            selected={selected}
                            onSelectAllClick={handleSelectAllClick}
                            order={order}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            data={filteredData}
                            isArrowDown={isArrowDown}
                            onStatusClick={handleStatusClick}
                            headersData={headers}
                            enableSelection={enableSection}
                            onRequestSort={handleRequestSort}
                        />
                        {/* Componente TABLEBODY */}
                        <TableBody>
                            {isLoading ? (
                                <TableRow hover sx={{ cursor: "default" }}>
                                    <TableCell colSpan={13} style={{ textAlign: "center" }}>
                                        <CircularProgress style={{ color: "#c4af6c" }} />
                                    </TableCell>
                                </TableRow>
                            ) : visibleRows?.length === 0 ? (
                                <TableRow hover sx={{ cursor: "default" }}>
                                    <TableCell colSpan={13} style={{ textAlign: "center" }}>
                                        <span>{mensaje}</span>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                visibleRows?.length &&
                                visibleRows.map((row) => (
                                    <>
                                        <TableRow
                                            hover
                                            key={row.id}
                                            sx={{ cursor: "pointer" }}
                                            // onClick={() => setExpandedRow(expandedRow === row.id ? null : row.id)}
                                            onClick={() => {onClickRow && onClickRow(row); accordionData && setExpandedRow(expandedRow === row.id ? null : row.id);} } 
                                            // onClick={() => onClickRow && onClickRow(row)} 

                                        >
                                            {enableSection && (
                                                <TableCell padding="checkbox" colSpan={row.colSpan}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selected.includes(row.id)}
                                                    />
                                                </TableCell>
                                            )}
                                            {headers?.map((header) => (
                                                <TableCell
                                                    align={(header['provincia'] || header['ciudad'] || header['referencia'] || header['agente']) ? 'left' : (header?.numeric ? 'right' : 'left')}
                                                    key={header.id}
                                                    onClick={(e) => {
                                                        if (typeof header.overrideFunc !== "function") {
                                                            e.stopPropagation();
                                                            onClickRow(row);
                                                            
                                                        }
                                                    }}
                                                >
                                                    {typeof header?.overrideFunc === "function"
                                                        ? header?.overrideFunc(row[header.id], row)
                                                        : row[header.id] || ""}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {expandedRow === row.id && (
                                            <TableRow>
                                                <TableCell colSpan={headers.length}>
                                                    {accordionData && accordionData(row)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                ))
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                {/* PAGINACION */}
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                    gap: 3,
                    alignItems: "center",
                }}
                >
                    <Box className="lae-flex lae-flex-row" sx={{ alignItems: "center" }}>
                        <Typography sx={{
                            fontSize: '0.9em',
                        }}
                        >Tamaño de página:</Typography>
                        <Select
                            value={rowsPerPage}
                            label="Age"
                            size="small"
                            variant="standard"
                            onChange={({ target }) => {
                                setPage(1);
                                setRowsPerPage(target.value);
                            }}
                        >
                            {[50, 100, 150, 200, 250, 300].map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box className="lae-flex lae-flex-row">
                        <Typography>
                            Nº Registros: {isLastPage ? data.meta?.total : data.rows.length * page} de{" "}
                            {data.meta?.total ?? 0}
                        </Typography>
                    </Box>
                    <Box className="lae-flex lae-flex-row" sx={{ gap: 0 }}>
                        <Button variant="text" onClick={handleBack} disabled={page === 1}>
                            <Iconify
                                icon="material-symbols:chevron-left"
                                sx={{
                                    color: page === 1 ? "gray" : "black",
                                    height: 24,
                                    width: 24,
                                }}
                            />
                        </Button>
                        <Button variant="text" onClick={handleNext} disabled={isLastPage}>
                            <Iconify
                                icon="material-symbols:chevron-right"
                                sx={{
                                    color: isLastPage ? "gray" : "black",
                                    height: 24,
                                    width: 24,
                                }}
                            />
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default DataTable;