import { useEffect, useState } from "react";
import {
    TextField,
    InputAdornment,
} from '@mui/material';

const HojaImporteForm = ({propiedad, setImporte, importe, handleChange, inputStyles, comision, tipoCom, formData}) => {
    // useEffect(() => {
    //     let totalImporte = 0;
    //     if (propiedad && propiedad.length > 0) {
    //         propiedad.forEach(prop => {
    //             // Usar precioinmo si está presente, si no usar importe, si ninguno está presente usar 0
    //             const valor = prop?.precioinmo !== undefined ? prop.precioinmo
    //                        : prop?.importe !== undefined ? prop.importe
    //                        : 0;
    //             totalImporte += parseFloat(valor) || 0;
    //         });
    //     }

    //     console.log("Setting totalImporte to:", totalImporte);
    //     setImporte(totalImporte);
    // }, [propiedad]);

    return (
       
        <>
            <TextField
                onChange={handleChange}
                label="Total"
                size="small"
                name="total"
                value={importe}
                sx={{ ...inputStyles, maxWidth: '30%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                    )
                }}
            />

            <TextField
                onChange={handleChange}
                label="Tipo Com."
                size="small"
                name="tipoCom"
                value={tipoCom ?? ''}
                sx={{ ...inputStyles, maxWidth: '20%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                    )
                }}
            />
            <TextField
                onChange={handleChange}
                label="Comisión"
                size="small"
                name="comision"
                value={comision}
                sx={{ ...inputStyles, maxWidth: '20%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                    )
                }}
            />
        </>
    );
};

export default HojaImporteForm;