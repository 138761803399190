import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSucursales } from '../../hooks';
import { 
    getHojaForm, 
    generateRef, 
    createHojaForm, 
    updateHojaForm, 
    verifyHojaForm 
} from '../../services';
import {
    Button,
    IconButton,
    Box,
    Paper,
    TextField,
    InputAdornment,
    Autocomplete,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    Checkbox,
    Grid,
    Popover,
    Select,
    FormControl,
    MenuItem
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import {
    Person,
    Save
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { FaSearch } from "react-icons/fa";
import { formatDateGuiones, getCurrentDate, SnackbarUtilities } from "../../utilities";
import {
    DrawerPropiedades,
    DrawerClientes,
    DrawerContactos,
    DrawerTrabajadores
} from "../../components";

import {
    HojaClienteForm,
    HojaImporteForm,
    HojaPropiedadForm,
    HojaRepartosForm,
    HojaPrescriptorForm,
} from "../../components/Forms";
import { useHojasForm, useUser } from "../../hooks";

import DeleteIcon from '@mui/icons-material/Delete';


const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};
const paperStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '10px',
    paddingLeft: '10px'
};
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const iconButtonStyles = {
    backgroundColor: 'transparent',
    color: 'red',
    '&:hover': {
        color: 'darkred'
    }
}
const HojaFormalizacion = () => {
    console.log();
    const user = useUser();
    const { rol } = user?.user;

    const { id } = useParams();
    const [formData, setFormData] = useState({
        propiedades: [],
        prescriptores: [],
        colaboradores: [],
    });
    const location = useLocation();
    // const { sucursal } = location?.state ?? null;
    const sucursal = null
    const { formatUnicData } = useHojasForm();
    const fechaOperacion = getCurrentDate();
    const [load, setLoad] = useState(true)
    const { setLoading, data, fetchData } = useSucursales();
    const [haya, setHaya] = useState(false);
    const [isCompartida, setIsCompartida] = useState(false);
    const [sucursalChoosed, setSucursalChoosed] = useState({});
    const [propiedades, setPropedades] = useState([]);
    const [comprador, setComprador] = useState({});
    const [vendedor, setVendedor] = useState({});
    const [prescriptor, setPrescriptor] = useState([]);
    const [colaborador, setColaborador] = useState([]);
    const [captador, setCaptador] = useState({});
    const [agenteVendedor, setAgenteVendedor] = useState({});
    const [importe, setImporte] = useState(0);
    const [comision, setComision] = useState(0);
    const [totalComision, setTotalComision] = useState(0);
    const [sidePropOpen, setSidePropOpen] = useState(false);
    const [sideCompradorOpen, setSideCompradorOpen] = useState(false);
    const [sideVendedorOpen, setSideVendedorOpen] = useState(false);
    const [sidePrescriptorOpen, setSidePrescriptorOpen] = useState(false);
    const [sideColaboradorOpen, setSideColaboradorOpen] = useState(false);
    const [sideCaptadorOpen, setSideCaptadorOpen] = useState(false);
    const [sideAgenteVendedorOpen, setSideAgenteVendedorOpen] = useState(false);
    const [showBtnCol, setShowBtnCol] = useState(false);
    const [showBtnCap, setShowBtnCap] = useState(false);
    const [showBtnVen, setShowBtnVen] = useState(false);
    const [showBtnPre, setShowBtnPre] = useState(false);
    const [needsRecalculation, setNeedsRecalculation] = useState(false);
    const [vinculo, setVinculo] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ide = open ? 'simple-popover' : undefined;
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const chooseSucursal = async (value) => {
        const id_sucursal = value?.id;
        setSucursalChoosed(sucursal || value);

        const ref = await generateRef(sucursal || id_sucursal);

        setFormData(prevState => ({
            ...prevState,
            ["referencia"]: ref,
            ["id_sucursal"]: sucursal || id_sucursal
        }));
    };
    const calculaImmporte = (propiedad, isAdding) => {
        let totalImporte = parseFloat(propiedad?.precioinmo) || parseFloat(propiedad?.importe) || 0;

        const updatedImporte = isAdding ? importe + totalImporte : importe - totalImporte;

        setImporte(updatedImporte);
        setFormData(prevState => ({
            ...prevState,
            ["total"]: updatedImporte

        }));
    };
    const handleChange = (e, propiedad) => {
        const { name, value } = e.target || {};
        let tipoCom = parseFloat(formData.tipoCom) / 100 || 0;
        let porLae = parseFloat(formData.porLae) / 100 || 0;
        let porPres = Array.isArray(formData.prescriptores) ? formData.prescriptores[0]?.porPres || 0 : formData?.prescriptores?.porPres || 0;
        let porCola = Array.isArray(formData.colaboradores) ? formData.colaboradores[0]?.porCola || 0 : formData?.colaboradores?.porCola || 0;
        let porCap = parseFloat(formData.porCaptador) / 100 || 0;
        let porVend = parseFloat(formData.porVendedor) / 100 || 0;

        setFormData(prevState => ({
            ...prevState,
            ["total"]: importe,
            ["fechaOperacion"]: fechaOperacion
        }));
        if (name === 'accion') {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }

        if (name === 'haya') {
            setHaya(!haya);
            setFormData(prevState => ({ ...prevState, [name]: e.target?.checked }));

        }
        if (name === 'opCompartida') {
            const isCompartida = e.target?.checked;
            setIsCompartida(isCompartida);
            setFormData(prevState => ({
                ...prevState,
                [name]: isCompartida,
                porLae: !isCompartida ? 0 : prevState.porLae
            }));
            let updatedPorLae = 0
            if (isCompartida === true) {
                updatedPorLae = formData.porLae / 100;
            }
            recalcularValores(isCompartida, tipoCom, updatedPorLae, porPres, porCola, porCap, porVend);

        }
        if (['direccion_propiedad', 'ciudad_propiedad', 'cp_propiedad', 'importe_propiedad', 'promocion'].includes(name)) {
            setPropedades(prevState =>
                prevState.map(prop =>
                    prop.id === propiedad.id ? { ...prop, [name.split('_')[0]]: value } : prop
                )
            );
            setFormData(prevState => ({
                ...prevState,
                propiedades: Array.isArray(prevState.propiedades) ?
                    prevState.propiedades.map(prop =>
                        prop.id === propiedad.id ? { ...prop, [name.split('_')[0]]: value } : prop
                    ) : []
            }));
        }
        if (['tipoCom', 'porLae', 'porCola', 'porCaptador', 'porPres', 'porVendedor'].includes(name)) {
            const parsedValue = parseFloat(value) / 100;

            if (name === 'porCaptador') {
                porCap = parsedValue;
            } else if (name === 'porCola') {
                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.colaboradores)) {
                        if (newFormData.colaboradores.length > 0) {
                            newFormData.colaboradores[0].porCola = value;
                        } else {
                            newFormData.colaboradores.push({ porCola: value });
                        }
                    } else if (typeof newFormData.colaboradores === 'object' && newFormData.colaboradores !== null) {
                        newFormData.colaboradores.porCola = value;
                    } else {
                        newFormData.colaboradores = { porCola: value };
                    }

                    return newFormData;
                });
                porCola = value;
                recalcularValores(isCompartida, tipoCom, porLae, porPres, porCola, porCap, porVend);
            } else if (name === 'porPres') {
                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.colaboradores)) {
                        if (newFormData.prescriptores.length > 0) {
                            newFormData.prescriptores[0].porPres = value;
                        } else {
                            newFormData.prescriptores.push({ porPres: value });
                        }
                    } else if (typeof newFormData.prescriptores === 'object' && newFormData.prescriptores !== null) {
                        newFormData.prescriptores.porPres = value;
                    } else {
                        newFormData.prescriptores = { porPres: value };
                    }

                    return newFormData;
                });
                porPres = value;
                recalcularValores(isCompartida, tipoCom, porLae, porPres, porCola, porCap, porVend);
            } else if (name === 'porVendedor') {
                porVend = parsedValue;
            } else if (name === 'tipoCom') {
                tipoCom = parsedValue;
            } else if (name === 'porLae') {
                porLae = parsedValue;
            }
            setFormData(prevState => ({ ...prevState, [name]: value }));
            recalcularValores(isCompartida, tipoCom, porLae, porPres, porCola, porCap, porVend);
        }
        if (name === 'vinculo') {
            setVinculo(value);
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }

        const UpdateedProps = propiedades?.map(prop => {
            return {
                id_prop: prop?.id,
                cp: prop?.cp,
                direccion: prop?.direccion,
                promocion: prop?.promocion,
                municipio: prop?.ciudad,
                importe: prop?.precioinmo || prop?.importe,
            }
        })
        setFormData(prevState => ({
            ...prevState,
            ["id_captador"]: formData.captador?.id,
            ["id_comprador"]: formData.comprador?.id,
            ["id_agVendedor"]: formData?.agenteVendedor?.id,
            ["id_vendedor"]: formData.vendedor?.id,
            ["propiedades"]: UpdateedProps

        }));
    };
    const handeEditChange = (e) => {
        const { name, value } = e.target || {};

        let tipoCom = parseFloat(formData.tipoCom) / 100 || 0;
        let porLae = parseFloat(formData.porLae) / 100 || 0;
        let porPres = Array.isArray(formData.prescriptores) ? parseFloat(formData.prescriptores[0]?.porPres) / 100 || 0 : 0;
        let porCola = Array.isArray(formData.colaboradores) ? parseFloat(formData.colaboradores[0]?.porCola) / 100 || 0 : formData?.colaboradores?.porCola || 0;
        let porCaptador = parseFloat(formData.porCaptador) / 100 || 0;
        let porVendedor = parseFloat(formData.porVendedor) / 100 || 0;
        console.log(name)

        if (name === 'total') {
            setFormData(prevState => ({
                ...prevState,
                ["total"]: value.trim() === '' ? '' : parseInt(value),
            }));
            setImporte(value.trim() === '' ? '' : parseInt(value))
            recalcularValoresToEdit(
                isCompartida,
                tipoCom,
                porLae,
                porPres,
                porCola,
                porCaptador,
                porVendedor
            );
        }
        if (name === 'accion') {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
        if (name === 'haya') {
            setHaya(!haya);
            setFormData(prevState => ({ ...prevState, [name]: e.target?.checked }));
        }
        if (name === 'opCompartida') {
            const isCompartida = e.target?.checked;
            setIsCompartida(isCompartida);
            setFormData(prevState => ({
                ...prevState,
                [name]: isCompartida,
                porLae: !isCompartida ? 0 : prevState.porLae,
                comisionLae: formData?.comisionTotal
            }));
            let updatedPorLae = 0
            if (isCompartida === true) {
                updatedPorLae = formData.porLae / 100;
            }
            // recalcularValores(isCompartida, tipoCom, updatedPorLae, porPres, porCola, porCap, porVend);
            recalcularValoresToEdit(
                isCompartida,
                tipoCom,
                porLae,
                porPres,
                porCola,
                porCaptador,
                porVendedor
            );
        }
        if (['tipoCom', 'porLae', 'porCola', 'porCaptador', 'porPres', 'porVendedor'].includes(name)) {
            const parsedValue = parseFloat(value) / 100;

            if (name === 'porCaptador') {
                porCaptador = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            } else if (name === 'porCola') {

                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.colaboradores)) {
                        if (newFormData.colaboradores.length > 0) {
                            newFormData.colaboradores[0].porCola = value;
                        } else {
                            newFormData.colaboradores.push({ porCola: value });
                        }
                    } else if (typeof newFormData.colaboradores === 'object' && newFormData.colaboradores !== null) {
                        newFormData.colaboradores.porCola = value;
                    } else {
                        newFormData.colaboradores = { porCola: value };
                    }

                    return newFormData;
                });
                porCola = value;

                recalcularValoresToEdit(isCompartida, tipoCom, porLae, porPres, porCola, porCaptador, porVendedor);
                // recalcularValoresToEdit(isCompartida, tipoCom, porLae, porPres, porCola, porCaptador, porVendedor);
            } else if (name === 'porPres') {
                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.prescriptores)) {
                        if (newFormData.prescriptores.length > 0) {
                            newFormData.prescriptores[0].porPres = value;
                        } else {
                            newFormData.prescriptores.push({ porPres: value });
                        }
                    } else if (typeof newFormData.prescriptores === 'object' && newFormData.prescriptores !== null) {
                        newFormData.prescriptores.porPres = value;
                    } else {
                        newFormData.prescriptores = { porPres: value };
                    }

                    return newFormData;
                });
                porPres = value;
                recalcularValoresToEdit(isCompartida, tipoCom, porLae, porPres, porCola, porCaptador, porVendedor);
            } else if (name === 'porVendedor') {
                porVendedor = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            } else if (name === 'tipoCom') {
                tipoCom = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            } else if (name === 'porLae') {
                porLae = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }

            setFormData(prevState => ({ ...prevState, [name]: value }));

            recalcularValoresToEdit(isCompartida, tipoCom, porLae, porPres, porCola, porCaptador, porVendedor);
        }
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const clean = (field) => {
        if (field === 'colaborador') {
            setColaborador([]);
            setFormData(prevState => {
                const { porCola, ...newState } = prevState;
                return {
                    ...newState,
                    colaboradores: [],
                };
            });
            recalcularValoresToEdit(
                formData.opCompartida,
                parseFloat(formData.tipoCom) / 100,
                parseFloat(formData.porLae) / 100,
                parseFloat(formData?.prescriptores?.porPres) / 100,
                parseFloat(0),
                parseFloat(formData.porCaptador) / 100,
                parseFloat(formData.porVendedor) / 100
            );
        } else if (field === 'captador') {
            setFormData(prevState => {
                const { captador, id_captador, porCaptador, ...newState } = prevState;
                recalcularValoresToEdit(
                    newState.opCompartida,
                    parseFloat(newState.tipoCom) / 100,
                    parseFloat(newState.porLae) / 100,
                    parseFloat(newState?.prescriptores?.porPres) / 100,
                    parseFloat(newState?.colaboradores?.porCola) / 100,
                    parseFloat(0),
                    parseFloat(newState.porVendedor) / 100
                );
                return newState;
            });
            console.log(formData)
        } else if (field === 'agenteVendedor') {
            setFormData(prevState => {
                const { agenteVendedor, porVendedor, ...newState } = prevState;
                return newState;
            });
            recalcularValoresToEdit(
                formData.opCompartida,
                parseFloat(formData.tipoCom) / 100,
                parseFloat(formData.porLae) / 100,
                parseFloat(formData?.prescriptores?.porPres) / 100,
                parseFloat(formData?.colaboradores?.porCola) / 100,
                parseFloat(formData.porCaptador) / 100,
                parseFloat(0)
            );
        } else if (field === 'prescriptor') {
            setPrescriptor([]);
            setFormData(prevState => {
                const { porPres, totalPrescriptor, ...newState } = prevState;
                return {
                    ...newState,
                    prescriptores: {},
                    vinculo: 'NADIE'
                };
            });

            recalcularValoresToEdit(
                formData.opCompartida,
                parseFloat(formData.tipoCom) / 100,
                parseFloat(formData.porLae) / 100,
                parseFloat(0),
                parseFloat(formData?.colaboradores?.porCola) / 100,
                parseFloat(formData.porCaptador) / 100,
                parseFloat(formData.porVendedor) / 100
            );
        }


    };
    const recalcularValores = (
        isCompartida = false,
        tipoCom = 0,
        porLae = 0,
        porPres = 0,
        porCola = 0,
        porCap = 0,
        porVend = 0
    ) => {
        // Cálculos necesarios
        const importeReducido = importe * (1 - tipoCom);
        const comision = importe - importeReducido;
        const importeNeto = comision * (1 - porLae);
        const totalComision = comision;
        const totalComisonNeta = isCompartida && porLae > 0 ? comision - importeNeto : comision;

        const importeColaborador = parseFloat((totalComisonNeta * parseFloat(porCola) / 100).toFixed(2));
        const importeCaptador = parseFloat((totalComisonNeta * porCap).toFixed(2));
        const importeVendedor = parseFloat((totalComisonNeta * porVend).toFixed(2));
        const importePrescriptor = parseFloat((
            vinculo === 'COLABORADOR' ? importeColaborador
                :
                vinculo === 'VENDEDOR' ? importeVendedor
                    :
                    vinculo === 'CAPTADOR' ? importeCaptador
                        :
                        totalComisonNeta) * parseFloat(porPres) / 100).toFixed(2);

        const sumRestos = importeColaborador + importeCaptador + importeVendedor;
        const miPres = prescriptor?.map(pr => ({
            id_pres: pr?.id,
            porPres: porPres,
            totalPrescriptor: importePrescriptor,
            nombre: pr?.nombre,
            apellidos: pr?.apellidos
        }));
        console.log(miPres)
        const miCol = colaborador?.map(col => ({
            id_col: col?.id,
            porCola: porCola,
            totalColaborador: vinculo === 'COLABORADOR' ? importeColaborador - importePrescriptor : importeColaborador,
            nombre: col?.nombre,
            apellidos: col?.apellidos
        }));

        console.log(miCol);
        setFormData(prevState => ({
            ...prevState,
            ["prescriptores"]: miPres,
            ["colaboradores"]: miCol,
            ["total"]: importe,
            //     ["totalCol"]: vinculo === 'COLABORADOR' ? importeColaborador - importePrescriptor : importeColaborador,
            ["totalCaptador"]: vinculo === 'CAPTADOR' ? importeCaptador - importePrescriptor : importeCaptador,
            ["totalVendedor"]: vinculo === 'VENDEDOR' ? importeVendedor - importePrescriptor : importeVendedor,
            //     ["totalPrescriptor"]: importePrescriptor,
            ["comisionTotal"]: totalComision,
            ["comisionLae"]: totalComisonNeta,
            ["totalComisionNeta"]: isCompartida ? totalComisonNeta - sumRestos : totalComision - sumRestos,
        }));

        // setComision(comision);
        setTotalComision(!isCompartida ? totalComision - sumRestos : totalComisonNeta - sumRestos);
    };
    const recalcularValoresToEdit = (
        isCompartida = false,
        tipoCom = 0,
        porLae = 0,
        porPres = 0,
        porCola = 0,
        porCap = 0,
        porVend = 0
    ) => {
        const { vinculo } = formData;
        const safeParseFloat = (value) => isNaN(value) ? 0 : parseFloat(value);

        const importeReducido = importe * (1 - tipoCom);
        const comision = importe - importeReducido;

        const importeNeto = comision * (1 - porLae);
        const totalComision = comision;
        const totalComisonNeta = (isCompartida || formData.opCompartida) && porLae > 0 ? comision - importeNeto : comision;

        const importeColaborador = safeParseFloat((totalComisonNeta * parseFloat(porCola) / 100).toFixed(2));
        const importeCaptador = safeParseFloat((totalComisonNeta * porCap).toFixed(2));
        const importeVendedor = safeParseFloat((totalComisonNeta * porVend).toFixed(2));

        const importePrescriptor = safeParseFloat((
            vinculo === 'COLABORADOR' ? importeColaborador
                :
                vinculo === 'VENDEDOR' ? importeVendedor
                    :
                    vinculo === 'CAPTADOR' ? importeCaptador
                        :
                        totalComisonNeta) * parseFloat(porPres) / 100).toFixed(2);

        const sumRestos = importeColaborador + importeCaptador + importeVendedor;

        setFormData(prevState => {
            // Asegurarte de que los colaboradores y prescriptores son arrays
            const colaboradores = Array.isArray(prevState?.colaboradores)
                ? [...prevState.colaboradores]
                : prevState?.colaboradores
                    ? [prevState.colaboradores]
                    : [];
            const prescriptores = Array.isArray(prevState?.prescriptores)
                ? [...prevState.prescriptores]
                : prevState?.prescriptores
                    ? [prevState.prescriptores]
                    : [];
            const updatedColaboradores = colaboradores.length > 0
                ? [
                    {
                        ...colaboradores[0],
                        totalColaborador: vinculo === 'COLABORADOR' ? importeColaborador - importePrescriptor : importeColaborador
                    },
                    ...colaboradores.slice(1)
                ]
                : [{ totalColaborador: vinculo === 'COLABORADOR' ? importeColaborador - importePrescriptor : importeColaborador }];

            const updatedPrescriptores = prescriptores.length > 0
                ? [
                    {
                        ...prescriptores[0],
                        totalPrescriptor: importePrescriptor
                    },
                    ...prescriptores.slice(1)
                ]
                : [{ totalPrescriptor: importePrescriptor }];

            const newFormData = {
                ...prevState,
                prescriptores: updatedPrescriptores,
                colaboradores: updatedColaboradores,
                totalCaptador: vinculo === 'CAPTADOR' ? importeCaptador - importePrescriptor : importeCaptador,
                totalVendedor: vinculo === 'VENDEDOR' ? importeVendedor - importePrescriptor : importeVendedor,
                comisionTotal: totalComision,
                comisionLae: totalComisonNeta,
                totalComisionNeta: (formData.opCompartida) ? totalComisonNeta - sumRestos : totalComision - sumRestos,
            };

            return newFormData;
        });

        setTotalComision((formData.opCompartida) ? totalComisonNeta - sumRestos : totalComision - sumRestos);

    }
    const formDataInfo = (persona) => {
        if (!persona || !persona.nombre || !persona.apellidos) {
            return '';
        }
        return `${persona.nombre} ${persona.apellidos}`;
    };
    const handleSubmit = async () => {
        console.log(formData);
        const response = await createHojaForm(formData);
        console.log(response);
        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización creada Correctamente");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    }
    const handleSubmitEdit = async () => {
        console.log(formData);
        const response = await updateHojaForm(id, formData);
        console.log(response);
        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización Editada Correctamente");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    }
    const handleVerify = async () => {
        const response =  await verifyHojaForm(id);
        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización Verificada");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    }
    useEffect(() => {
        if (id) {
            fetcherData();
        } else {
            fetchData({ offset: 1, limit: 1000, filtros: { estado: 1 } });
            setLoad(!load);
        }
    }, [id]);

    const fetcherData = async () => {
        const response = await getHojaForm(id);
        let datoFormateado = formatUnicData(response);
        const { propiedades } = datoFormateado;

        setFormData(datoFormateado);
        setPropedades(propiedades);
        propiedades.forEach(propiedad => {
            calculaImmporte(propiedad, true);
        });


    }
    console.log(formData)
    return (
        <>
            <div className="container">
                {/* <BreadCrumb /> */}
                <div className="top-line">
                    <div style={{ textAlign: "start" }}>
                        <h3>{id ? 'Editar' : 'Crear'} Hoja de Formalización</h3>
                    </div>
                    <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                        {formData.estado === "PENDIENTE" && (rol.code === "ROOT" || rol.code === "ADMIN" || rol.code === "RDOF" || rol.code === "COMM") ?
                            <Button
                                variant="outline-dark"
                                size="small"
                                sx={{ ...styledButton, marginRight: '10px' }}
                                onClick={() => handleVerify()}
                                startIcon={
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography sx={{ paddingLeft: '5px', fontSize: '13px !important' }}>
                                            Verificar
                                        </Typography>
                                    </Box>
                                }
                            />
                            :
                            <></>
                        }

                        <Button
                            variant="outline-dark"
                            size="small"
                            sx={styledButton}
                            onClick={() => id ? handleSubmitEdit() : handleSubmit()}
                            startIcon={
                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Save />
                                    <Typography sx={{ paddingLeft: '5px', fontSize: '13px !important' }}>
                                        Guardar
                                    </Typography>
                                </Box>
                            }
                        />
                    </div>

                </div>
                <div className="middleline">
                    <Box sx={{ width: "90%", minHeight: "100vh" }}>
                        {id == undefined ?
                            <>
                                <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                                    <Autocomplete
                                        fullWidth
                                        options={data.rows}
                                        onChange={(event, newValue) => chooseSucursal(newValue)}
                                        getOptionLabel={(option) => `LAE HOMES - ${option?.municipio}`}
                                        // value={data.rows.filter(suc => suc.id === sucursal)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Elige una sucursal"
                                                name="sucursal"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FaSearch />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        ...inputStyles,
                                                        color: '#33383b', // Color del texto
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#33383b', // Color del label cuando está enfocado
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        ...inputStyles,
                                                        borderColor: '#c4af6c', // Color del borde
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#c4af6c', // Color del borde cuando está enfocado
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Paper>
                                <Divider variant="middle" />
                            </>
                            :
                            <></>
                        }

                        {/* DATOS PRINCIPALES */}
                        <Paper elevation={0} sx={paperStyles}>
                            <TextField
                                onChange={(e) => handleChange(e)}
                                id="filled-helperText"
                                size="small"
                                name="referencia"
                                disabled
                                value={formData?.referencia}
                                placeholder="Referencia"
                                sx={{
                                    ...inputStyles,
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#33383b', // Necesario para algunos navegadores
                                    },
                                }}
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="fechaOp"
                                label="Fecha Operación"
                                type="date"
                                size="small"
                                name="fechaOperacion"
                                value={id ? formatDateGuiones(formData?.fechaOperacion) : fechaOperacion}
                                sx={inputStyles}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {/* ACCIONES */}
                            {/* <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }} > */}
                            <RadioGroup name="accion" row onChange={handleChange} value={formData?.accion ?? ''}>
                                <FormControlLabel
                                    value="ALQUILER"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="ALQUILER"
                                />
                                <FormControlLabel
                                    value="COMPRA_VENTA"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="COMPRA - VENTA"
                                />
                                <FormControlLabel
                                    value="TRASPASO"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="TRASPASO"
                                />
                                <FormControlLabel
                                    value="ALQ_OPCION_COMPRA"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="ALQ. OPCIÓN COMPRA"
                                />
                            </RadioGroup>

                        </Paper>
                        {/* DATOS INMUEBLE */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Propiedades
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSidePropOpen(!sidePropOpen) }}
                                    // startIcon={<AddHomeIcon />} // Si existe
                                    // Alternativamente, usa ambos íconos
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <HomeIcon />
                                        </Box>
                                    }

                                />
                            </Box>
                            {/* PROPIEDADES */}
                            {(propiedades.length > 0) ? (
                                <>
                                    {(propiedades).map((prop, index) => (
                                        <React.Fragment key={index}>
                                            <HojaPropiedadForm
                                                propiedad={prop}
                                                handleChange={handleChange}
                                                inputStyles={inputStyles}
                                                comision={comision}
                                            />
                                            <Divider
                                                variant="middle"
                                                sx={{
                                                    borderColor: '#c4af6c',
                                                    borderWidth: '1.5px',
                                                    marginBottom: '20px',
                                                    marginTop: '20px',
                                                    width: '83%'
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                            <TextField
                                onChange={handleChange}
                                label="Observación"
                                size="small"
                                name="observacion"
                                value={formData.observacion}
                                sx={{ ...inputStyles, maxWidth: '83%' }}
                                fullWidth
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginBottom: '20px' }} />
                        {/* DATOS COMPRADOR */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Comprador
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSideCompradorOpen(!sideCompradorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <Person />
                                        </Box>
                                    }
                                />
                            </Box>
                            <HojaClienteForm
                                handleChange={handleChange}
                                cliente={formData.comprador}
                                inputStyles={inputStyles}
                            />

                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* DATOS VENDEDOR */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Vendedor
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSideVendedorOpen(!sideVendedorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <Person />
                                        </Box>
                                    }
                                />
                            </Box>
                            <HojaClienteForm
                                handleChange={handleChange}
                                cliente={formData.vendedor}
                                inputStyles={inputStyles}
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* AGENTES */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Datos de agentes
                                </Typography>

                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideColaboradorOpen(!sideColaboradorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            Colaborador
                                        </Box>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideCaptadorOpen(!sideCaptadorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            Captador
                                        </Box>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideAgenteVendedorOpen(!sideAgenteVendedorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            Vendedor
                                        </Box>
                                    }
                                />

                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, fontSize: '16px !important' }}
                                    onClick={() => { setSidePrescriptorOpen(!sidePrescriptorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }}>
                                            <AddIcon />
                                            Prescriptor
                                        </Box>
                                    }
                                />

                            </Box>

                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="colaborador"
                                label="Colaborador"
                                size="small"
                                name="colaborador"
                                value={
                                    Array.isArray(formData?.colaboradores) && formData.colaboradores.length > 0
                                        ? formDataInfo(formData.colaboradores[0])
                                        : formData?.colaboradores
                                            ? formDataInfo(formData.colaboradores)
                                            : ''
                                }
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porCola"
                                label=""
                                size="small"
                                name="porCola"
                                placeholder="00.00"
                                value={
                                    Array.isArray(formData?.colaboradores) && formData.colaboradores.length > 0
                                        ? formData.colaboradores[0]?.porCola ?? ''
                                        : typeof formData?.colaboradores === 'object' && formData.colaboradores !== null
                                            ? formData.colaboradores?.porCola ?? ''
                                            : ''
                                }
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            {!formData?.colaboradores || (Array.isArray(formData.colaboradores) && formData.colaboradores.length === 0) ? (
                                <></>
                            ) : (
                                <IconButton sx={iconButtonStyles} onClick={() => clean('colaborador')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="captador"
                                label="Captador"
                                size="small"
                                name="captador"
                                value={formDataInfo(formData.captador) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porCaptador"
                                label=""
                                size="small"
                                name="porCaptador"
                                placeholder="00.00"
                                value={formData.porCaptador ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            {formData.captador ?
                                <IconButton sx={iconButtonStyles} onClick={() => clean('captador')}>
                                    <DeleteIcon />
                                </IconButton>
                                :
                                <></>
                            }
                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                            <TextField
                                onChange={handleChange}
                                id="vendedor"
                                label="Vendedor"
                                size="small"
                                name="vendedor"
                                value={formDataInfo(formData.agenteVendedor) || ''}
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porVend"
                                label=""
                                size="small"
                                name="porVendedor"
                                placeholder="00.00"
                                value={formData?.porVendedor ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            {formData.agenteVendedor ?
                                <IconButton sx={iconButtonStyles} onClick={() => clean('agenteVendedor')}>
                                    <DeleteIcon />
                                </IconButton>
                                :
                                <></>
                            }
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="prescriptor"
                                label="Prescriptor"
                                size="small"
                                name="prescriptor"
                                value={
                                    formData?.prescriptores && formData.prescriptores.length > 0
                                        ?
                                        formDataInfo(formData.prescriptores[0])
                                        : formDataInfo(formData.prescriptores)
                                }
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <HojaPrescriptorForm
                                handleChange={id ? handeEditChange : handleChange}
                                vinculo={vinculo || formData.vinculo}
                                anchorEl={anchorEl}
                                open={open}
                                handleClick={handleClick}
                                handleClose={handleClose}
                                ide={ide}
                                formData={formData}
                            />
                            {!formData?.prescriptores || (Array.isArray(formData.prescriptores) && formData.prescriptores.length === 0) ? (
                                <></>
                            ) : (
                                <IconButton sx={iconButtonStyles} onClick={() => clean('prescriptor')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginTop: '2%' }} />
                        {/* DATOS ECONOMICOS */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Datos Económicos
                                </Typography>

                            </Box>
                        </Paper>
                        {/* IMPORTES */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <HojaImporteForm
                                setImporte={setImporte}
                                formData={formData}
                                setFormData={setFormData}
                                importe={formData?.total ?? importe}
                                propiedad={formData?.propiedades}
                                comision={formData?.comisionTotal ?? comision}
                                tipoCom={formData?.tipoCom}
                                inputStyles={inputStyles}
                                handleChange={id ? handeEditChange : handleChange} // 
                            />
                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <FormControlLabel
                                disabled={isCompartida}
                                sx={{ marginRight: '30px' }}
                                control={
                                    <Checkbox
                                        sx={CheckboxStyles}
                                        checked={formData.haya}
                                        onChange={id ? handeEditChange : handleChange}
                                        name="haya"
                                    />
                                }
                                label={<Typography sx={{ fontSize: '13.5px' }}>HAYA</Typography>}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                disabled={haya}
                                sx={{ marginRight: '30px' }}
                                control={
                                    <Checkbox
                                        sx={CheckboxStyles}
                                        checked={!!formData?.opCompartida}
                                        onChange={id ? handeEditChange : handleChange}
                                        name="opCompartida"
                                    />
                                }
                                label={<Typography sx={{ fontSize: '13.5px' }}>OPERACIÓN COMPARTIDA</Typography>}
                                labelPlacement="end"
                            />
                            {formData?.opCompartida == true ?
                                <>
                                    <TextField
                                        onChange={id ? handeEditChange : handleChange}
                                        label="Porcentaje"
                                        size="small"
                                        name="porLae"
                                        value={formData.porLae}
                                        sx={{ ...inputStyles, maxWidth: '20%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                            )
                                        }}
                                    />
                                    <TextField
                                        onChange={id ? handeEditChange : handleChange}
                                        label="Comisión neta"
                                        size="small"
                                        name="comisonLae"
                                        value={formData.comisionLae ?? ''}
                                        sx={{ ...inputStyles, maxWidth: '20%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                            )
                                        }}
                                    />
                                </>
                                :
                                <></>
                            }
                            <TextField
                                onChange={handleChange}
                                label="Observación"
                                size="small"
                                name="obserDatosEco"
                                value={formData.obserDatosEco}
                                sx={{ ...inputStyles, width: '81%' }}
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* REPARTO */}
                        <Grid container spacing={2} columns={16}>
                            <HojaRepartosForm
                                handleChange={handleChange}
                                formData={formData}
                            />
                            <Grid item xs={8}>
                                <Box sx={{
                                    maxWidth: '50%',
                                    marginTop: '30%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '0.5%'
                                }}>
                                    <Typography variant="h5">Total: </Typography>
                                    <Typography variant="h3" sx={{ marginLeft: '10px' }}>
                                        {formData?.totalComisionNeta?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
            <DrawerPropiedades
                open={sidePropOpen}
                setOpen={setSidePropOpen}
                setPropiedad={setFormData}
                setPropedadesAux={setPropedades}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                propiedades={propiedades}
                calculaImmporte={calculaImmporte}
            />
            {/* COMPRADOR */}
            <DrawerClientes
                open={sideCompradorOpen}
                toSave={'comprador'}
                setOpen={setSideCompradorOpen}
                setCliente={setFormData}
                cliente={formData.comprador}
            />
            {/* VENDEDOR */}
            <DrawerClientes
                open={sideVendedorOpen}
                toSave={'vendedor'}
                setOpen={setSideVendedorOpen}
                setCliente={setFormData}
                cliente={formData.vendedor}
            />

            {/* COLABORADOR */}
            <DrawerContactos
                open={sideColaboradorOpen}
                setOpen={setSideColaboradorOpen}
                setContacto={setFormData}
                setColaborador={setColaborador}
                toSave={'colaboradores'}
                contacto={formData?.colaboradores && formData.colaboradores.length > 0
                    ?
                    formDataInfo(formData.colaboradores[0])
                    : formDataInfo(formData.colaboradores)
                }
            />
            <DrawerTrabajadores
                open={sideCaptadorOpen}
                setOpen={setSideCaptadorOpen}
                setTrabajador={setFormData}
                toSave={'captador'}
                trabajador={formData?.captador}
            />
            <DrawerTrabajadores
                open={sideAgenteVendedorOpen}
                setOpen={setSideAgenteVendedorOpen}
                setTrabajador={setFormData}
                toSave={'agenteVendedor'}
                trabajador={formData?.agVendedor}
            />
            {/* PRESCRIPTOR */}
            <DrawerContactos
                open={sidePrescriptorOpen}
                setOpen={setSidePrescriptorOpen}
                setContacto={setFormData}
                setPrescriptor={setPrescriptor}
                toSave={'prescriptores'}
                contacto={formData?.colaboradores && formData.colaboradores.length > 0
                    ?
                    formDataInfo(formData.colaboradores[0])
                    : ''
                }
            />
        </>
    );
};

export default HojaFormalizacion;