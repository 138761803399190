import * as React from 'react';
import { useSucursales } from '../../hooks';
import { useEffect, useState } from 'react';
import { Drawer, Box, Typography } from '@mui/material';
import DataTable from '../Tables/DataTable';

const DrawerSucusal = ({open, setOpen, setSucursal, publicarPropertie}) =>{
    const {loading, data, fetchData} = useSucursales();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({});
    const [value, setValue] = useState('');
    const headers = [
        {
            provincia:"provincia",
            numeric: false,
            disablePadding: false,
            label: "Provincia",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.provincia}
                </Typography>
            ),
        },
        {
            municipio:"municipio",
            numeric: false,
            disablePadding: false,
            label: "Municipio",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.municipio}
                </Typography>
            ),
        },
        {
            cp:"cp",
            numeric: false,
            disablePadding: false,
            label: "CP",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.cp}
                </Typography>
            ),
        },
        {
            email:"email",
            numeric: false,
            disablePadding: false,
            label: "Email",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            ),
        },
    ];
   
    const asignarSucursal = (datos) => {
        publicarPropertie(datos);
    }
   
    return ( 
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className='container'>
            <Box component="section" sx={{ p: 3,}}>
                <h2>Sucursales</h2>
                <p>Para poder publicar esta propiedad deber vincularla a una de nuestras sucursales:</p>
            </Box>
            <div className='middleline'>
                
                <DataTable
                    headers = {headers}
                    data = {data}
                    isLoading = {loading}
                    fetcher = {fetchData}
                    mensaje={"No hay sucursales"}
                    currentPage = {currentPage}
                    setCurrentPage = {setCurrentPage}
                    filtros = {filtros || ''}
                    onClickRow={(row) => asignarSucursal(row)}
                /> 
            </div>
        </div>
    </Drawer>
    );
};

export default DrawerSucusal;