import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Link } from "react-router-dom";
import {
    Button,
    Typography,
    Chip
} from '@mui/material';
import {
    Download,
    XCircleFill,
    PencilFill,
    EyeFill,
    TrashFill,
} from "react-bootstrap-icons";
import { DataTable } from "../../components";
import { AiFillFileAdd } from "react-icons/ai";
import { useHojasForm } from "../../hooks";
import { deleteHojaForm } from "../../services";
import Swal from "sweetalert2";
import { useUser } from "../../hooks/UserContextLogin";
const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};
const HojasFormalizacion = () => {
    const { user, setUser } = useUser();

    const headers = [
        {
            referencia: "referencia",
            numeric: false,
            disablePadding: false,
            label: "Referencia",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.referencia}
                </Typography>
            ),
        },
        {
            oficina: "oficina",
            numeric: false,
            disablePadding: false,
            label: "Oficina",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.sucursal?.nombre_sucursal}
                </Typography>
            ),
        },
        {
            fechaOp: "fechaOp",
            numeric: false,
            disablePadding: false,
            label: "Fecha Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row?.fechaOperacion}
                </Typography>
            ),
        },
        {
            agente: "agente",
            numeric: false,
            disablePadding: false,
            label: "Agente",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {/* {row.agente} */}
                </Typography>
            ),
        },
        {
            inmueble: "inmueble",
            numeric: false,
            disablePadding: false,
            label: "Ref/Tipo inmmueble",
            overrideFunc: (data, row) => (
                row?.propiedades?.map(prop => (
                    <Typography
                        sx={{
                            color: "#33383b !important",
                            "&:hover": { color: "#33383b" },
                            "&:active": { color: "#33383b" },
                            fontSize: "0.9rem",
                            fontWeight: "1rem"
                        }}
                    >
                        {prop.referencia} / {prop.tipo_ofer}
                    </Typography>
                ))

            ),
        },
        {
            operacion: "operacion",
            numeric: false,
            disablePadding: false,
            label: "Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.accion}
                </Typography>
            ),
        },
        {
            estado: "estado",
            numeric: false,
            disablePadding: false,
            label: "Estado",
            overrideFunc: (data, row) => (
                <>
                    {row.estado === 'PENDIENTE'
                        ?
                        <Chip label={row.estado} color="warning" />
                        :
                        <Chip label={row.estado} color="success" />
                    }
                </>
            ),
        },
        {
            precio: "precio",
            numeric: false,
            disablePadding: false,
            label: "Precio",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.total.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}
                </Typography>
            ),
        },
        {

            numeric: false,
            disablePadding: false,
            label: "",
            overrideFunc: (data, row) => (
                <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Link to={`/hojaFormalizacion/editar/${row.id}`}>
                            <button className="btn btn-outline-dark">
                                <PencilFill />
                            </button>
                        </Link>
                        <Link to={`#`} onClick={() => handleDelete(row.id)}>
                            <button className="btn btn-outline-danger">
                                <TrashFill />
                            </button>
                        </Link>
                    </div>
                </>
            ),
        }
    ];
    useEffect(()=>{
        console.log(user.rol?.permisos.acciones);
         
         console.log(user.rol?.permisos.acciones.find(permiso => permiso.action === "delete" && permiso.resource === "HojaDeFormalizacion") ?? "404");
         
    },[])
    // const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    // const [data, setData] = useState(test_data);
    const [filtros, setFiltros] = useState({});
    const { loading, limite, data, fetchData } = useHojasForm();
    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Está seguro de que quiere eliminar la publicación?",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: `Si`,
            confirmButtonColor: "#48cc90",
            denyButtonText: `No`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const res = await deleteHojaForm(id);
                if (res.status === 201) {
                    await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
                    Swal.fire(
                        "¡Eliminado!",
                        "Publicacion Eliminada correctamente",
                        "success"
                    );

                }
            }
        });
    }
    return (
        <>
            <div className="container">
                {/* <BreadCrumb /> */}
                <div className="topline">
                    <div>
                        <h3>Hojas de formalización</h3>
                    </div>
                    <div>
                        {/* <button className='btn btn-default me-2' onClick={() => reportProperties(filtrosSeleccionados)}><Download /></button> */}
                        <Link to={'/hojaFormalizacion/crear'} state={{sucursal: 1}}>
                            <Button size="medium" sx={styledButton} startIcon={<AiFillFileAdd />}>Crear Hoja</Button>
                        </Link>
                    </div>
                </div>
                <div className="middleline">
                    {/* FILTROS */}
                </div>
                <div className="middleline">
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        fetcher={fetchData}
                        mensaje={"No hay Hojas disponibles"}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filtros={filtros}

                    />
                </div>
            </div>
        </>
    );
};

export default HojasFormalizacion;