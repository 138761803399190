import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { SnackbarUtilities } from "../../utilities";
// import Button from '@mui/material/Button';
// import SendIcon from '@mui/icons-material/Send';
// import Chip from '@mui/material/Chip';
// import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import FormControl from '@mui/material/FormControl';
// import Box from '@mui/material/Box';
// import Checkbox from '@mui/material/Checkbox';
// import MenuItem from '@mui/material/MenuItem';
// import TextField from '@mui/material/TextField';
import { Button, TextField, Box, FormControlLabel, Checkbox, RadioGroup, Radio, MenuItem } from "@mui/material";


export default function ClientesForm() {
  const [editMode, setEditMode] = useState(false);

  const initialValues = {
    tipo_solicitud: 0,
    razon_social: '',
    cif: '',
    nombre: '',
    apellidos: '',
    dni: '',
    fechaNac: '',
    nombre_comercial: '',
    representante_legal: '',
    telefono: '',
    segundo_telefono: '',
    email: '',
    segundo_email: '',
    direccion: '',
    municipio: '',
    cp: '',
    provincia: '',
    sucursal_id: '',
    trabajador_id: '',
    observaciones: '',
  };

  const validationSchema = Yup.object().shape({
    tipo_solicitud: Yup.boolean().required('Campo obligatorio'),
    razon_social: Yup.string().required('Campo obligatorio'),
    cif: Yup.string().required('Campo obligatorio'),
    nombre: Yup.string().required('Campo obligatorio'),
    apellidos: Yup.string().required('Campo obligatorio'),
    dni: Yup.string().notRequired().nullable(),
    fechaNac: Yup.string().nullable(),
    nombre_comercial: Yup.string(),
    representante_legal: Yup.string(),
    telefono: '',
    segundo_telefono: Yup.string().matches(/^[0-9+\-().\s]*$/, 'Formato de teléfono no válido', { excludeEmptyString: true }).nullable(),
    email: Yup.string().email('Formato de correo no válido').required('Campo obligatorio'),
    segundo_email: Yup.string().email('Formato de correo no válido').required('Campo obligatorio'),
    direccion: Yup.string(),
    municipio: Yup.string(),
    cp: Yup.string(),
    provincia: Yup.string(),
    sucursal_id: Yup.number().required('Campo obligatorio'),
    trabajador_id: Yup.number().required('Campo obligatorio'),
    observaciones: Yup.string(),

  });

  // Usa la función de validación personalizada
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("join 111");

      if (values) {
        // const formData = new FormData();
        console.log("join");
        console.log(values);
        // Agrega todos los valores del formulario a formData

        // const formData = createFormData(values);
        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }
        // const response = editMode ? await updateTrabajador(trabajadorId, formData) : await createTrabajador(formData);

        // console.log(response);
        // if (response.status == 201) {
        //     if (trabajadorId) {
        //         SnackbarUtilities.success("Trabajador Editado correctamente!!");
        //     }else {
        //         SnackbarUtilities.success("Trabajador creado y dado de Alta correctamente!!");
        //     }
        //     setTimeout(() => {
        //         history(`/trabajadores`);
        //     }, 2000);
        // } else if (response.response?.data?.message) {
        //     SnackbarUtilities.error(response.response?.data?.message);
        // } else {
        //     SnackbarUtilities.error("error");
        // }
      } else {
        SnackbarUtilities.error("Error en algun validador del campo");
      }
    },
  });






  return (
    <div className="container">
      {/* <BreadCrumb /> */}
      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>{editMode ? 'Editar Cliente' : 'Crear Cliente'}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
          <Button type="submit" variant="outline-dark">
            Guardar
          </Button>
        </div>

        <div className="middleline">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                {/* <FormControlLabel
                  sx={{ marginTop: 0 }}
                  control={
                    <Checkbox
                      checked={values.tipo_solicitud}
                      size="small"
                      name="tipo_solicitud"
                      color="default"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.tipo_solicitud && Boolean(errors.tipo_solicitud)}
                      helperText={touched.tipo_solicitud && errors.tipo_solicitud}
                    />
                  }
                  label="Soy Empresa" /> */}
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Tipo Solicitante"
                  name="tipo_solicitud"
                  margin="normal"
                  sx={inputStyles}
                  value={values.tipo_solicitud}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tipo_solicitud && Boolean(errors.tipo_solicitud)}
                  helperText={touched.tipo_solicitud && errors.tipo_solicitud}
                >
                  {["Soy Persona", "Soy Empresa"].map((name, index) => (
                    <MenuItem key={name} value={index}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {(values.tipo_solicitud == 0) && <div>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nombre && Boolean(errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
              </div>}
              {/* <div>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nombre && Boolean(errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Apellidos"
                  name="apellidos"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.apellidos}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.apellidos && Boolean(errors.apellidos)}
                  helperText={touched.apellidos && errors.apellidos}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Cargo"
                  name="cargo"
                  margin="normal"
                  size="small"
                  sx={inputStyles}
                  value={values.cargo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.cargo && Boolean(errors.cargo)}
                  helperText={touched.cargo && errors.cargo}
                />
              </div> */}

            </Box>
          </div>
        </div>
      </form>
    </div>
  )
}
const inputStyles = {
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c4af6c',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#333', // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0
};

