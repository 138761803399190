import { useState } from "react";
import {listTrabajadores} from "../services";

function useTrabajadores(){
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: []
    });
    const[loading, setLoading] = useState(true);
    const formatData = (data) => {
        // data.map((item)=>console.log(item?.sucursal?.municipio));
        try {
            return data.map((row) => (
                {
                 id: row.id,
                 sucursal_id: row.sucursal_id,
                 user_id: row.user_id,
                 nombre: row.nombre,
                 apellidos: row.apellidos,
                 telefono: row.telefono,
                 fechaNac: row.fechaNac,
                 dni: row.dni,
                 telefonoPersonal: row.telefonoPersonal,
                 email: row.email,
                 cargo: row.cargo,
                 status: row.status,
                 web: row.web,
                 url_profile_img: row.url_profile_img,
                 trabajador_sucursal: row.trabajador_sucursal?.id,
                 verified:row.user_trabajador?.verified,
                 departamento: row.trabajador_departamento?.map(element => element.nombre).join(' | ')
             }));
        } catch (error) {
            console.log(error);
        }

    };
    const fetchData = async({offset, limit, filtros,orderBy,order}) => {
        setLimite(limit);
        let payload;
        if (orderBy&&order) {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit),orderBy:`${orderBy},${order}`},
                filtros
            };
        }else {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit)},
                filtros
            };
        }

        try {
            setLoading(true);
            // console.log("Fetching data with payload:", payload);
            let response = await listTrabajadores(payload);
            // console.log("Response received:", response);
            const { meta, data } = response;
            console.log(formatData(data));
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        limite,
    };


}

export default useTrabajadores;