import { useState } from "react";
import { listSucursales } from "../services";

function useSucursales(){
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: []
    });
    const[loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data.map((row) => ({
            id: row.id,
            id_ext: row.id_ext,
            provincia: row.provincia,
            municipio: row.municipio,
            direccion: row.direccion,
            telefono: row.telefono,
            cp: row.cp,
            email: row.email,
            estado:row.estado
        }));
    }
    
    const fetchData = async({offset, limit, filtros,orderBy,order}) => {
        // setLimite(limit);
        // const payload = {
        //     params: {page: offset, limit},
        //     filtros
        // };
        setLimite(limit);
        let payload;
        if (orderBy&&order) {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit),orderBy:`${orderBy},${order}`},
                filtros
            };
        }else {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit)},
                filtros
            };
        }

        try {
            setLoading(true);
            let response = await listSucursales(payload);   
            const { meta, data } = response;
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        limite,
    };
}
export default useSucursales;