export const createFormData = (data, formData = new FormData(), parentKey = '')=> {
    
        const jsonData = {}; // Objeto para almacenar los datos que no son archivos

        // Iteramos sobre las propiedades del objeto `data`
        Object.entries(data).forEach(([key, value]) => {
            if (value instanceof File) {
                // Si el valor es un archivo, lo añadimos directamente al FormData
                formData.append(key, value);
            } else {
                // Si no es un archivo, lo añadimos al objeto jsonData
                jsonData[key] = value;
            }
        });

        // Añadimos los datos como un único campo JSON en el FormData
        formData.append('data', JSON.stringify(jsonData));

        // // Para depurar: Imprimimos el FormData
        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }

        return formData;
}