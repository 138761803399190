import { useState } from 'react';
import { Drawer, Box, Typography, Checkbox, Button } from '@mui/material';
import DataTable from '../Tables/DataTable';
import { useClientes } from '../../hooks';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const DrawerClientes = ({ open, setOpen, setCliente, cliente, toSave }) => {
    const { loading, data, fetchData } =  useClientes();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({});
    const headers = [ 
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addCliente(e, row)}
                    checked={cliente?.id === row?.id}
                />
            ),
        },
        {
            numero_documento:'numero_documento',
            numeric: false,
            disabledPadding: false,
            label: 'Nif',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_documento}
                </Typography>
            )
        },
        {
            nombre:'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos:'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            telefono:'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email:'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const addCliente = (e, cli) => {
        const {checked} = e.target; 
        if(checked){
        
            setCliente(prevState => ({ ...prevState, [`${toSave}`]: cli }));
        }else{
            setCliente(prevState => ({ ...prevState, [`${toSave}`]: '' }));
        }
    };
    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <div className='container'>
                    <Box component="section" sx={{ p: 3 }}>
                        <h2>Clientes</h2>
                        <p>Debes Seleccionar un Cliente</p>
                    </Box>
                </div>
                <div className='middleline'>
                    <DataTable 
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        fetcher={fetchData}
                        mensaje={"No hay clientes disponibles"}
                    />
                </div>
            </Drawer> 
        </>
    );
}

export default DrawerClientes;