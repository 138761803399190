import axios from "axios";

const urlPath = "clientes";

export const listClientes = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCliente = async (id) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const createCliente = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/`, data);
        return response;
    } catch (error) {
        console.log(error);
    }
};