import axios from "axios";

const urlPath = "graficos";

export const listpropiedadesPrecio = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/graficoQuesoHojaFormalizacion`, data.filtros, {
            params: data.params,
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const listpropiedadesPrecioBarras = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/graficosBarras`, data.filtros);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const listpropiedadesPrecioBarrasMultiColor = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/graficoBarrasMultiColor`, data.filtros);
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};