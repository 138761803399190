import { useState, useContext, useEffect } from "react";
import { listHojasForm } from "../services/hojaForm.service";
import { formatDate } from "../utilities";
function useHojasForm() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);
    const formatData = (data) => {
        return data.map((row) => {
            return {
                id: row.id,
                referencia: row.referencia,
                id_sucursal: row.id_sucursal,
                fechaOperacion: formatDate(row?.fechaOperacion),
                accion: row.accion,
                tipoCom: row.tipoCom,
                id_comprador: row.id_comprador,
                id_captador: row.id_captador,
                porCaptador: row.porCaptador,
                totalCaptador: row.totalCaptador,
                id_vendedor: row.id_vendedor,
                id_agVendedor: row.id_agVendedor,
                porVendedor: row.porVendedor,
                totalVendedor: row.totalVendedor,
                total: row.total,
                comisionTotal: row.comisionTotal,
                porLae: row.porLae,
                comisionLae: row.comisionLae,
                totalComisionNeta: row.totalComisionNeta,
                opCompartida: row.opCompartida,
                haya: row.haya,
                propiedades: row.propiedades,
                observacion: row.observacion,
                sucursal: row.su_sucursal,
                estado: row.estado
            };
        });
    };
    const formatUnicData = (data) => {
        const propiedades = data.propiedades.map(prop => ({
            referencia: prop.referencia,
            ciudad: prop.ciudad,
            id: prop.id,
            precioinmo: prop.precioinmo,
            provincia: prop.provincia,
            promocion: prop.rHojafPropiedad?.promocion || ''  
        }));
        const colaboradores = data.colaboradores.map(col => ({
            apellidos:col.apellidos,
            cp:col.cp,
            direccion:col.direccion,
            email:col.email,
            id:col.id,
            nombre:col.nombre,
            poblacion:col.poblacion,
            porCola:col.rHojafColaborador.porcentaje,
            totalColaborador: col.rHojafColaborador.totalColaborador
        }));
        const prescriptores = data.prescriptores.map(pres => ({
            apellidos: pres.apellidos,
            cp: pres.cp,
            direccion: pres.direccion,
            email: pres.email,
            id: pres.id,
            nombre: pres.nombre,
            poblacion: pres.poblacion,
            porPres: pres.rHojaPrescriptor?.porcentaje || '',
            totalPrescriptor: pres.rHojaPrescriptor?.totalPrescriptor
        }));
        let new_data = {
            id: data.id,
            accion: data.accion,
            referencia: data.referencia,
            id_sucursal: data.id_sucursal,
            comisionTotal:data.comisionTotal,
            tipoCom: data.tipoCom,
            comprador: data.comprador,
            vendedor: data.vendedor,
            comisionLae: data.comisionLae,
            total: data.total,
            sucursal:data.su_sucursal,
            captador: data.captador,
            agenteVendedor: data.agenteVendedor,
            totalCaptador: data.totalCaptador,
            totalComisionNeta: data.totalComisionNeta,
            totalVendedor: data.totalVendedor,
            fechaOperacion: data.fechaOperacion,
            haya: data.haya,
            vinculo:data.vinculo,
            opCompartida: data.opCompartida,
            id_agVendedor: data.id_agVendedor,
            id_captador: data.id_captador,
            id_comprador: data.id_comprador,
            id_sucursal: data.id_sucursal,
            id_vendedor: data.id_vendedor,
            observacion: data.observacion,
            opCompartida: data.opCompartida,
            porCaptador: data.porCaptador,
            porLae: data.porLae,    
            porVendedor: data.porVendedor,
            colaboradores: colaboradores[0],
            prescriptores: prescriptores[0],
            propiedades: propiedades,
            estado: data.estado
        };
        return new_data;
    };
    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listHojasForm(payload);
            console.log(response);
            const { meta, data } = response.data;
            console.log(data)
            setData({
                meta,
                rows: formatData(data),
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        formatUnicData,
        limite
    };

};

export default useHojasForm;