import { useState } from 'react';
import { Drawer, Box, Typography, Checkbox } from '@mui/material';
import DataTable from '../Tables/DataTable';
import { useTrabajadores } from '../../hooks';

const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};

const DrawerTrabajadores = ({ open, setOpen, setTrabajador, trabajador, toSave }) => {
    const { loading, data, fetchData } = useTrabajadores();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({});
   
    const headers = [ 
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addTrabajador(e, row)}
                    checked={trabajador?.id === row?.id}
                />
            ),
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];

    const addTrabajador = (e, tr) => {
        const { checked } = e.target; 
        if (checked) {
            setTrabajador(prevState => ({ ...prevState, [`${toSave}`]: tr }));
        } else {
            setTrabajador(prevState => ({ ...prevState,}));
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <div className='container'>
                <Box component="section" sx={{ p: 3 }}>
                    <h2>Trabajadores</h2>
                    <p>Debes seleccionar un trabajador</p>
                </Box>
            </div>
            <div className='middleline'>
                <DataTable 
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    currentPage={currentPage}
                    fetcher={fetchData}
                    mensaje={"No hay trabajadores disponibles"}
                />
            </div>
        </Drawer>
    );
}

export default DrawerTrabajadores;
