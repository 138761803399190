import { useSnackbar } from "notistack";

let useSnackbarRef;
export const SnackbarUtilitiesConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const SnackbarUtilities = {
  toast(msg, variant = "default",duration = 1900) {
    useSnackbarRef.enqueueSnackbar(msg, { variant, preventDuplicate: true,autoHideDuration: duration });
  },
  success(msg) {
    this.toast(msg, "success");
  },
  error(msg) {
    this.toast(msg, "error");
  },
  info(msg) {
    this.toast(msg, "info");
  },
  warning(msg) {
    this.toast(msg, "warning");
  },
};
