import React, { createContext, useState } from "react";

export const UserContext = createContext(null);
export const UserProvider = ({ children }) => {
    const [usuario, setUsuario] = useState(null);
    return (
        <UserContext.Provider value={{usuario, setUsuario}}>
            {children}
        </UserContext.Provider>
    );
}