import axios from "axios";
import { SnackbarUtilities, getValidationError } from "../utilities";
import { logout } from "../services";

// Establecer la URL base de Axios
const { REACT_APP_API_URL } = process.env;
axios.defaults.baseURL = REACT_APP_API_URL;

export const AxiosInterceptor = () => {
  axios.interceptors.request.use((request) => {

    const token = localStorage.getItem("token");

    
    // Excluir la solicitud de login
    console.log(request.url);
    const direccionesPermitidas = ["/auth/login/CRM","/auth/verify","auth/reenviarVerificacion","auth/recoverPass","users",]
    if (direccionesPermitidas.some(direccion => request.url.startsWith(direccion)) && !token) {
      return request; // Permite la solicitud si la URL comienza con alguna de las direcciones permitidas
    }


    if (!token) {
      // alert("Error: No se ha encontrado un token de acceso.");
      console.log("Error: No se ha encontrado un token de acceso.");
      
      logout();
      return Promise.reject("Token de acceso no encontrado");
    }

    // Añadir el token de autorización al encabezado de la solicitud
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
      provide: "laehomes",
    };

    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log(error);
      // console.log(error.config.data);
      if (error.response) {
        // SnackbarUtilities.error(
        //   getValidationError(error.code) +
        //   " " +
        //   (error.response.data?.message ?? error.request.response)
        // );
        if (error.response.status === 401) {
          console.log("Error 401: No autorizado, cerrando sesión...");
        }else if (error.config.data == '{"token":null}') {
          console.log("aaaaaaa");
          logout(); 

        }

      } else {
        console.error("Error sin respuesta del servidor", error);
      }

      return Promise.reject(error);
    }
  );
};
