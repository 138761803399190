import {
    Box,
    TextField,
    InputAdornment,
    Paper,
    Typography,
    Grid
} from '@mui/material';

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const paperStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    marginBottom: '10px',
    paddingLeft: '10px'
};

const HojaRepartosForm = ({ handleChange, formData }) => {

    
    return (
        <>
            <Grid item xs={8}>
                <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                            Reparto
                        </Typography>
                        <Box sx={{ width: '100%' }} >
                            {/* COMMISION COLABORADOR */}
                            <TextField
                                onChange={handleChange}
                                size="small"
                                label="Colaborador"
                                name="porCola"
                           inputProps={{ readOnly: true }}
                                value={
                                    Array.isArray(formData?.colaboradores) && formData.colaboradores.length > 0
                                    ? formData.colaboradores[0]?.porCola ?? ''
                                    : typeof formData?.colaboradores === 'object' && formData.colaboradores !== null
                                        ? formData.colaboradores?.porCola ?? ''
                                        : ''
                                }
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Colaborador"
                                size="small"
                                name="totalCol"
                                inputProps={{ readOnly: true }}
                                value={
                                    Array.isArray(formData?.colaboradores) && formData.colaboradores.length > 0
                                    ? formData.colaboradores[0]?.totalColaborador ?? ''
                                    : typeof formData?.colaboradores === 'object' && formData.colaboradores !== null
                                        ? formData.colaboradores?.totalColaborador ?? ''
                                        : ''
                                }
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />

                            {/* COMISION CAPTADOR */}
                            <TextField
                                onChange={handleChange}
                                label="Captador"
                                size="small"
                                name="porCaptador"
                                inputProps={{ readOnly: true }}
                                value={formData.porCaptador ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Captador"
                                size="small"
                                type='text'
                                name="totalCaptador"
                                inputProps={{ readOnly: true }}
                                value={formData.totalCaptador ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />

                            {/* COMMISION VENDEDOR */}

                            <TextField
                                onChange={handleChange}
                                label="Vendedor"
                                size="small"
                                name="porVend"
                                inputProps={{ readOnly: true }}
                                value={formData.porVendedor  ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Vendedor"
                                size="small"
                                name="totalVendedor"
                                inputProps={{ readOnly: true }}
                                value={formData.totalVendedor  ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{   
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                            {/* COMMISION PRESCRIPTOR */}
                            <TextField
                                onChange={handleChange}
                                label="Prescriptor"
                                size="small"
                                name="porPres"
                                inputProps={{ readOnly: true }}
                                value={
                                    Array.isArray(formData?.prescriptores) && formData.prescriptores.length > 0
                                    ? formData.prescriptores[0]?.porPres ?? ''
                                    : typeof formData?.prescriptores === 'object' && formData.prescriptores !== null
                                        ? formData.prescriptores?.porPres ?? ''
                                        : ''
                                }
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Prescriptor"
                                size="small"
                                name="totalPrescriptor"
                                inputProps={{ readOnly: true }}
                                value={
                                    Array.isArray(formData?.prescriptores) && formData.prescriptores.length > 0
                                    ? formData.prescriptores[0]?.totalPrescriptor ?? ''
                                    : typeof formData?.prescriptores === 'object' && formData.prescriptores !== null
                                        ? formData.prescriptores?.totalPrescriptor ?? ''
                                        : ''
                                }
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default HojaRepartosForm;