import React, { useContext, useState,useEffect } from "react";
import { deleteTrabajador, listTrabajadores } from "../../services";
import { DataTable } from "../../components";
import {
    Typography,
} from "@mui/material";
import { jsonRenameItems, transforHeader } from "../../utilities/transformHeaders";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarUtilities } from "../../utilities";
import { useNavigate, Link } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useUser } from "../../hooks/UserContextLogin";
import ButtonPermission from "../../utilities/ButtonPermission";
import useClientes from "../../hooks/useContactos";

const Clientes = () => {

    const { data, fetchData, loading, limite } = useClientes();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState();
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    const iconColumn = [{
        item: (name) => {
            return (
                <Typography
                    sx={{

                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    <div style={{ marginLeft: "30px" }}>{name?.Verificado ? <CheckIcon sx={{ color: "green", fontSize: 38 }} /> : <CloseIcon sx={{ color: "red", fontSize: 38 }} />}</div>
                </Typography>
            )
        },
        position: 8,
        titleHeader: "Verificado",
        nameColumn: "verified"
    }, {
        item: (name) => {
            return (
                <div style={{ display: "flex", justifyContent: "left" }}>
                    <Link to={`/clientes/form/${name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark" >
                        <EditIcon />
                    </Link>
                    <button className="btn btn-outline-dark" onClick={() => deleteItem(name.id)} style={{ marginLeft: "20px" }}>
                        <DeleteIcon />
                    </button>
                </div>
            )
        },
        position: 9,
        titleHeader: "Acciones",
        nameColumn: ""
    }]

    const deleteItem = async (id) => {
        try {
            const response = await deleteTrabajador(id);
            console.log(response);
            if (response == 200) {
                SnackbarUtilities.success("Trabajador eliminado correctamente!");
                fetchData({ offset: currentPage, limit: 1000, filtros: { estado: 1 } });
                // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
            } else {
                SnackbarUtilities.error("Error al eliminar el trabajador. Inténtalo de nuevo más tarde.");
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = async () => {
        // window.open(window.location.origin + `/trabajadores/create`, "_top");
        navigate("/clientes/form");
    }


    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Clientes</h3>
                </div>
                <div>
                    <ButtonPermission onClick={handleClick} variant="contained" endIcon={<NoteAddIcon />}
                        action="edit"
                        resource="Trabajadores"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Crear
                    </ButtonPermission>
                </div>
            </div>

            <DataTable
                data={{ rows: jsonRenameItems(data.rows, "Trabajadores", "titulos_tabla"), meta: data.meta }}
                fetcher={fetchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                mensaje={"No hay trabajadores disponibles"}
                isLoading={loading}
                // onClickRow={(row)=>console.log(row.id)}
                filtros={filtros || ''}
                headers={transforHeader(jsonRenameItems(data.rows, "Trabajadores", "titulos_tabla"), ["id", "DNI", "Estado", "Departamento", "Cargo", "sucursal_id", "Verificado", "user_trabajador", "fechaNac", "User ID", "status", "Sucursal", "Fecha de Nacimiento", "web", "url_profile_img",], iconColumn, "Trabajadores", "titulos_tabla", user)}
            />

        </div>
    );
}

export default Clientes;