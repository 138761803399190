import React, { useEffect, useState } from 'react';
import BreadCrumb from '../navbar/Breadcrumb';
import BarrasGrafico from './BarrasGrafico';
import { listpropiedadesPrecioBarras, listpropiedadesPrecioBarrasMultiColor } from '../../services/graficos.service';
import QuesoGrafico from './QuesoGrafico';
import * as Yup from 'yup';
import { useFormValidation } from '../../utilities/formValidator';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { listSucursales } from "../../services";
import MenuItem from '@mui/material/MenuItem';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import TextField from '@mui/material/TextField';
import useGraficos from "../../hooks/useGraficos";
import BarrasGraficoMultiColor from './BarrasGraficoMultiColor';
import CircularProgress from '@mui/material/CircularProgress'; // Importa el componente de carga
import { getCurrentDate } from '../../utilities';
import Resumen from './ResumenGraficos';
import { useUser } from "../../hooks/UserContextLogin";

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default function GraficosComponente() {
    const [dataBarras, setDataBarras] = useState(null);
    const [dataBarrasMultiColor, setDataBarrasMultiColor] = useState(null);
    // const [sucursalMap, setSucursalMap] = useState(null);
    const { data: datagraficoQueso, fetchData, loading } = useGraficos();
    const [preciosGraficosQueso, setPreciosGraficosQueso] = useState([]);
    const { user, setUser } = useUser();


    const orderByMode = ["day", "month"];
    const comisionSelect = [{ name: "Total Propiedades", db_name: "total" }, { name: "Comisión Bruta", db_name: "comisionTotal" }, { name: "Comisión Lae-Homes", db_name: "comisionLae" }, { name: "Comisión Neta Lae-Homes", db_name: "totalComisionNeta" }];
    const accionSelect = [{ name: "COMPRA - VENTA", db_name: "COMPRA_VENTA" }, { name: "ALQ OPCIÓN COMPRA", db_name: "ALQ_OPCION_COMPRA" }, { name: "ALQUILER", db_name: "ALQUILER" }, { name: "TRASPASO", db_name: "TRASPASO" }];
    //FORM DATA 
    const initialValues = {
        fechaInicio: '2024-01-01',
        fechaFinal: getCurrentDate() ?? '2024-07-01',
        byCustomDate: 'month',
        tipoComision: 'total',
        tipoAccion: 'COMPRA_VENTA',
        id_sucursal: user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas') ? user?.usuarioDatos?.sucursal_id : null
    };

    const validationSchema = Yup.object().shape({
        fechaInicio: Yup.date().required("Por favor, seleccione una fecha"),
        fechaFinal: Yup.date().required("Por favor, seleccione una fecha"),
        byCustomDate: Yup.string().notRequired().nullable(),
        tipoComision: Yup.string().notRequired().nullable(),
        tipoAccion: Yup.string().notRequired().nullable(),
        id_sucursal: Yup.string().notRequired().nullable(),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log(values);
            if (values) {
                //console.log(values);
                fetchData({ offset: 1, limit: 1000, filtros: values });
                if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
                    await fetchDataBarra(values);
                }else {
                    await fetchDataBarraColores(values);

                }
            }
        },
    });
    const handleResetCustom = () => {
        // setDataBarrasMultiColor(null);
        handleReset()
    }
    // const fetchSucursales = async () => {
    //     const response = await listSucursales({ offset: 1, limit: 1000 });
    //     setSucursalMap(response.data || []);
    // };

    const fetchDataBarra = async (filtros) => {
        //console.log(filtros);
        // { "fechaInicio": "2024-05-30","fechaFinal": "2024-07-11", "sucursal_id": 10386 }
        // const filtros = { fechaInicio: '2024-06-01', fechaFinal: '2024-07-11',sucursal_id:10386 }; // Ejemplo de filtros
        // const filtros = { fechaInicio: '2024-01-01', fechaFinal: '2024-07-11', byCustomDate: 'month', "sucursal_id": 10386 }; // Ejemplo de filtros
        const data = await listpropiedadesPrecioBarras({ filtros: filtros });
        // console.log(data.chartData);
        // console.log(data.series);
        // console.log(data);
        
        // console.log(dataMultiColor);

        setDataBarras(data); // Ajusta esto según la estructura de los datos retornados
    };
    const fetchDataBarraColores = async (filtros) => {
        const dataMultiColor = await listpropiedadesPrecioBarrasMultiColor({ filtros: filtros });
        setDataBarrasMultiColor(dataMultiColor); // Ajusta esto según la estructura de los datos retornados

    }
    useEffect(() => {
        // console.log(user);

        // console.log(values.id_sucursal);
        // console.log(user.rol.permisos.customViews);
        // console.log(user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas') );
        
        if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
            fetchDataBarra(values);
        }else {
            fetchDataBarraColores(initialValues);
        }
        fetchData({ offset: 1, limit: 1000, filtros: values });
        // console.log(datagraficoQueso);

        // fetchSucursales();
    }, []);

    function formatearDatos(precios) {
        // console.log(precios);
        const data = Object.entries(precios.groupedPrices).map(([key, value]) => ({
            id: key,
            value: Math.round(value.porcentaje * 100) / 100,
            label: key + ` ${value.totalSucursal.toLocaleString('es-ES')} €`,
            total: Math.round(value.porcentaje * 100) / 100 + " %"
        }));
        //console.log(data);
        return data;
    }

    // useEffect(() => {
    //     if (!loading && datagraficoQueso) {
    //         // setPreciosGraficosQueso(formatearDatos(datagraficoQueso));
    //         // fetchDataBarra(values);
    //         console.log("entrooo");
    //     }
    //     console.log("entrooo2");

    // }, [loading, datagraficoQueso]);

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                        gap: 2,
                    }}
                >
                    <div>
                        <TextField
                            sx={inputStyles}
                            label="Desde"
                            name="fechaInicio"
                            type="date"
                            size="small"
                            margin="normal"
                            value={values.fechaInicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fechaInicio && Boolean(errors.fechaInicio)}
                            helperText={touched.fechaInicio && errors.fechaInicio}
                        />
                    </div>
                    <div>
                        <TextField
                            sx={inputStyles}
                            label="Hasta"
                            name="fechaFinal"
                            type="date"
                            size="small"
                            margin="normal"
                            value={values.fechaFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fechaFinal && Boolean(errors.fechaFinal)}
                            helperText={touched.fechaFinal && errors.fechaFinal}
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo Comisión"
                            size="small"
                            name="tipoComision"
                            margin="normal"
                            sx={inputStyles}
                            value={values.tipoComision}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.tipoComision && Boolean(errors.tipoComision)}
                            helperText={touched.tipoComision && errors.tipoComision}
                        >
                            {comisionSelect ? comisionSelect.map((option) => (
                                <MenuItem key={option.db_name} value={option.db_name}>
                                    {option.name}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo Acción"
                            size="small"
                            name="tipoAccion"
                            margin="normal"
                            sx={inputStyles}
                            value={values.tipoAccion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.tipoAccion && Boolean(errors.tipoAccion)}
                            helperText={touched.tipoAccion && errors.tipoAccion}
                        >
                            {accionSelect ? accionSelect.map((option) => (
                                <MenuItem key={option.db_name} value={option.db_name}>
                                    {option.name}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency2"
                            select
                            label="Ordenar Por"
                            size="small"
                            name="byCustomDate"
                            margin="normal"
                            sx={inputStyles}
                            value={values.byCustomDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.byCustomDate && Boolean(errors.byCustomDate)}
                            helperText={touched.byCustomDate && errors.byCustomDate}
                        >
                            {orderByMode ? orderByMode.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", gap: 8 }}>
                        <Button sx={{ color: "#000", backgroundColor: "#c4af6c" }} fullWidth type="submit">
                            Buscar
                        </Button>
                        <Button sx={{ color: "#000", backgroundColor: "#c4af6c" }} fullWidth onClick={() => {
                            fetchData({ offset: 1, limit: 1000, filtros: initialValues });
                            if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
                                fetchDataBarra(initialValues)
                            }else {
                                fetchDataBarraColores(initialValues);
                            }
                            console.log(values);
                            handleResetCustom();
                        }}>
                            <CleaningServicesIcon />
                        </Button>
                    </div>

                </Box>

            </form>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', marginTop: "2em" }}>
                        {preciosGraficosQueso && <div><QuesoGrafico preciosGraficos={formatearDatos(datagraficoQueso)} total={datagraficoQueso.totalPrecios} /></div>}
                        {dataBarrasMultiColor && <div><BarrasGraficoMultiColor series={dataBarrasMultiColor.series} xLabels={dataBarrasMultiColor.xLabels} /></div>}
                        {dataBarras && <div><BarrasGrafico data={dataBarras.chartData} seriesColumn={dataBarras.series} /></div>}
                    </div>
                    {/* <Resumen dataQueso={datagraficoQueso && datagraficoQueso} dataBarras={dataBarrasMultiColor && dataBarrasMultiColor}></Resumen> */}

                </div>

            )}


        </div>
    );
}
