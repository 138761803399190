import { useState } from "react";
import {
    TextField,
} from '@mui/material';
const HojaPropiedadForm = ({ handleChange, propiedad, inputStyles }) => {
    const handleChangeWrapper = (e) => {
        handleChange(e, propiedad);
    };
  
    return (
        <>
            <TextField
                onChange={handleChangeWrapper}
                label="Dirección"
                size="small"
                name="direccion_propiedad"
                value={propiedad.direccion ?? propiedad.rHojafPropiedad?.direccion}
                sx={{ ...inputStyles, maxWidth: '81%', }}
                fullWidth
            />
            <TextField
                onChange={handleChangeWrapper}
                label="Población"
                size="small"
                name="ciudad_propiedad"
                value={propiedad.ciudad}
                sx={{ ...inputStyles, maxWidth: '30%' }}
                fullWidth
            />
            <TextField
                onChange={handleChangeWrapper}
                label="CP"
                size="small"
                name="cp_propiedad"
                value={propiedad.cp ?? propiedad.rHojafPropiedad?.cp}
                sx={{ ...inputStyles, maxWidth: '15%' }}
                fullWidth
            />
            <TextField
                onChange={handleChangeWrapper}
                label="Promoción"
                size="small"
                name="promocion"
                value={propiedad.promocion ?? propiedad.rHojafPropiedad?.promocion}
                sx={{ ...inputStyles, maxWidth: '34%' }}
                fullWidth
            />
        </>
    );
}

export default HojaPropiedadForm;