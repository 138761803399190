import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Button, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";

import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import axios from 'axios';
import { useSucursales } from '../../hooks';

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c', // Cambia el color del borde cuando está seleccionado
        },
    },
};

const estados = [
    'Para reformar',
    'De origen',
    'Reformar Parcialmente',
    'Entrar a vivir',
    'Buen estado',
    'Semireformado',
    'Reformado',
    'Seminuevo',
    'Nuevo',
    'Obra Nueva',
    'En construcción',
    'En proyecto'
];
const colaboradorArray = ["INMOVILLA" , "ASICVAL" , "RESALES" ];
const FiltrosPropiedades = ({ filtros, handleSubmit, setFiltros }) => {
    const [provincia, setProvincia] = useState("");
    const [ciudad, setCiudad] = useState({});
    const { setLoading, data: { rows: sucursal } = {}, fetchData } = useSucursales();
    const [sucusalValue,setSucursalValue] = useState([]);
    const [colaborador, setColaborador] = useState([]);
    const [referencia, setReferencia] = useState("");
    const [agente, setAgente] = useState("");
    const [conservacion, setConservacion] = useState("");
    const [published, setPublished] = useState("");
    useEffect(() => {
        fetchData({ offset: 1, limit: 1000, filtros: { estado: true } })
        // console.log(sucursal);
  
    }, [])

    useEffect(()=>{
        console.log(filtros);
    },[filtros])

    const cleanFilter = ()=>{
        setFiltros({})
        setReferencia("")
        setAgente("")
        setProvincia('')
        setColaborador("")
        setSucursalValue("")
        setConservacion("")
        setPublished("")
    }

    const handleChange = (e) => {
        const { name,value } = e.target;

        if (name=="referencia") {
            setReferencia(value);
            
        }
        if (name=="agente") {
            setAgente(value)
        }
        // console.log(name);
        setFiltros({
            ...filtros,
            [name]: e.target.value
        });

        if (name == 'published') {
            // console.log(e.target.value);
            if (e.target.value === "Sí") {
                setPublished(value)
                setFiltros({
                    ...filtros,
                    [name]: true
                });
            } else if (e.target.value === "No") {
                setPublished(value)
                setFiltros({
                    ...filtros,
                    [name]: false
                });
            } else if (e.target.value === "Todos") {
                setPublished(value)
                const { [name]: omit, ...rest } = filtros; // Omitir el filtro de 'published'
                setFiltros(rest);
            }
        }
        if (name == 'provincia') {
            const selectedProvincia = provinces.find(pr => pr.code === e.target.value);
            setProvincia(selectedProvincia.code);
            setFiltros({
                ...filtros,
                [name]: selectedProvincia?.name.toLocaleUpperCase()
            });
        }
        if (name == 'ciudad') {
            console.log(e.target.value);
            const selectedCiudad = municipalities.find(mun => mun.provCode === provincia && mun.code === e.target.value);
            setCiudad(selectedCiudad);
            // console.log(provincia)
            // console.log(selectedCiudad);
            setFiltros({
                ...filtros,
                [name]: selectedCiudad?.name
            });
        }
        if (name == 'from_mls') {
            setColaborador(value);
            setFiltros({
                ...filtros,
                [name]: e.target.value
            });
        }
        console.log(name);
        if (name=="sucursal") {
            setSucursalValue(value);
            setFiltros({
                ...filtros,
                [name]: e.target.value
            });
        }
        if (name=="conservacion") {

            setConservacion(value);
            setFiltros({
                ...filtros,
                [name]: e.target.value
            });
        }

    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <TextField
                        name='referencia'
                        value={referencia}
                        size="small"
                        label="Referencia"
                        onChange={(e) => handleChange(e)}
                        sx={inputStyles}
                    />
                    <TextField
                        name="agente"
                        value={agente}
                        size="small"
                        label="Agente"
                        onChange={(e) => handleChange(e)}
                        sx={inputStyles}
                    />
                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Provincia
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Provincia"
                            name="provincia"
                            value={provincia}
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {provinces.map(province =>

                                <MenuItem value={province.code}>{province.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Ciudad
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Ciudad"
                            name="ciudad"
                            value={ciudad?.code}
                            disabled={
                                (filtros.provincia !== '' || filtros.provincia !== undefined)
                                    ?
                                    false
                                    :
                                    true
                            }
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {municipalities.map(mun => {
                                if (parseInt(mun?.provCode) === parseInt(provincia)) {
                                    return (<MenuItem value={mun.code}>{mun.name}</MenuItem>)
                                }
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Colaborador
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Colaborador"
                            name="from_mls"
                            value={colaborador}
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {colaboradorArray.map(item =>

                                <MenuItem value={item}>{item}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Sucursal
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sucursal"
                            name="sucursal"
                            value={sucusalValue}
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {sucursal.map(item =>

                                <MenuItem value={item.id_ext}>{item.municipio}</MenuItem>
                            )

                            }

                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Estado
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Estado"
                            name="conservacion"
                            value={conservacion}
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {estados.map(est => {

                                return (<MenuItem value={est}>{est}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 223 }} size="small">
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#33383b',
                            }
                        }}
                            id="demo-simple-select-label"
                        >
                            Publicado
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Publicado"
                            name="published"
                            value={published}
                            onChange={(e) => { handleChange(e) }}
                            sx={{
                                textAlign: 'left',
                                '&.MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c4af6c', // <------------------ outline-color on focus
                                    },
                                },
                            }}
                        >
                            {['Sí', 'No', 'Todos'].map(publi => {

                                return (<MenuItem value={publi}>{publi}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}
                        onClick={() => { handleSubmit() }}
                    >
                        <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#33383b',
                            backgroundColor: '#c4af6c',
                            borderColor: 'transparent',
                            '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}
                        onClick={() => { cleanFilter() }}
                    >
                        <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                    </Button>
                </Box>
            </Box>
            <style jsx='true'>
                {`
                    .form-check-input-filter-f {
                        width: 40px;
                        height: 40px;
                        margin-top: 0;
                        vertical-align: top;
                        background-color: #fff;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        border: 1px solid rgba(0,0,0,0.25);
                        appearance: none;
                        color-adjust: exact;
                        border-radius: 4px;
                        border: 1px solid rgb(196,196,196) !important;
                        margin-left: 15px;
                    }
                    .form-check-filter {
                        padding-left: 0!important;
                        text-align: left !important;
                    }
                    .form-check-inline-f {
                        display: inline-block;
                        margin-right: 0rem;
                    }
                    .form-check-input-filter-f:checked {
                        background-color: #33383b ;
                        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
                    }
                    .form-check-label-filter-f {
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        margin-top: 7px;
                        margin-left: 0px;
                    }
                    .form-check-label-filter-m {
                        margin-top: 7px;
                        margin-left: 15px;
                    }
                `}
            </style>
        </>
    );
};

export default FiltrosPropiedades;