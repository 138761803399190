import { useState, useContext, useEffect } from "react";
import { listClientes } from "../services";
function useClientes() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data.map((row) => (
            {
                id: row.id,
                nombre: row.nombre,
                apellidos: row.apellidos,
                direccion: row.direccion,
                cp: row.cp,
                poblacion: row.poblacion,
                telefono: row.telefono,
                email: row.email,
                estado_civil: row.estado_civil,
                tipo_documento: row.tipo_documento,
                numero_documento: row.numero_documento,
                nomconyuge: row.nomconyuge,
                tipo_doc_conyuge: row.tipo_doc_conyuge,
                idConyuge: row.idConyuge,
                rep_legal: row.rep_legal,
                tel_rep_legal: row.tel_rep_legal,
            }));
    };

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;

        console.log(orderBy, order)
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `id,${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listClientes(payload);
            const {meta, data} = response;
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        loading,
        fetchData,
        limite
    };
};

export default useClientes;
