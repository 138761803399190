import React, { useEffect, useState } from "react";
import { saveSucursal, updateSucursal, sucursalDetails } from "../../services/sucursales.service";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { useParams } from "react-router-dom";
import { InputGroup, Form, Button } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import municipalities from "@doncicuto/es-municipalities";
import provinces from "@doncicuto/es-provinces";
import { SnackbarUtilities } from "../../utilities";
const FormSucursal = () => {
    const { id } = useParams();
   
    const provincias = provinces;
    // const municipios = municipalities;
    const [sucursal, setSucursal] = useState(null);
    const [municipios , setMunicipios ] = useState([]);
    const [nombre_sucursal, setNombre_sucursal] = useState('');
    const [provincia, setProvincia] = useState({});
    const [municipio, setMunicipio] = useState({});
    const [direccion, setDireccion] = useState('');
    const [cp, setCp] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [id_ext, set_id_ext] = useState('');
    const [tipo, setTipo] = useState('');
    

    useEffect(()=> {
       if(id){
        fetchData();
       } 
    },[]);
    console.log(sucursal);
    const fetchData = async () => {
        const response = await sucursalDetails(id);
        setSucursal(response);
    }
     useEffect(() => {
        if (sucursal) {
            let miPR = provinces.find(pr => pr.name === sucursal?.provincia);
            console.log(sucursal?.nombre_sucursal);
            setNombre_sucursal(sucursal?.nombre_sucursal || '');
            console.log(miPR);
            setProvincia(miPR);
            const filteredMunicipios = miPR ? municipalities.filter(mu => mu?.provCode == miPR?.code) : [];
            setMunicipios(filteredMunicipios);
            setMunicipio(filteredMunicipios.find(m => m.name == sucursal?.municipio));
            // setProvincia(miPR ? miPR.code : ''); // Ajusta según la estructura de datos
            setDireccion(sucursal?.direccion);
            setCp(sucursal.cp);
            setTelefono(sucursal?.telefono);
            setEmail(sucursal?.email);
            setTipo(sucursal?.tipo);
            set_id_ext(sucursal.id_ext);
        }
    }, [sucursal]);

    console.log(email);
    const handleChangeProvincias =  (evt) => {
    
        if(evt.target.value !== ''){
            const selectedProvincia = provincias.find(pr => pr.code === evt.target.value);
            setProvincia(selectedProvincia);
            if (selectedProvincia) {
                const filteredMunicipios = municipalities.filter(mu => mu.provCode === selectedProvincia.code);
                setMunicipios(filteredMunicipios);
            }
        }else{
            setProvincia('');
            setMunicipios([]);
        }
    
        // handleChangeMunicipios({ target: { value: '' } }); // Reset the municipios select
    }
    // console.log(provincia)
    const handleChangeMunicipios = (evt) => {
        
        const selectedMunicipio = municipios.find(pr => pr.code === evt.target.value);
        console.log(selectedMunicipio)
        if (selectedMunicipio) {
            setMunicipio(selectedMunicipio);
        }
    }
    const handleChangeDireccion = (evt) => {
        // console.log(evt.target.value)
        setDireccion(evt.target.value)
    }
    const handleChangeCP = (evt) => {
        setCp(evt.target.value);
    }
    const handleChangeTelefono = (val, coun, ev, form) => {
        setTelefono(ev.target.value);
    }
    const handleChangeEmail = (evt) => {
        setEmail(evt.target.value);
    }
    const handleChangeNombreeSucursal = (evt) => {
        setNombre_sucursal(evt.target.value);
    }
    const handleChangeTipo = (evt) => {
        setTipo(evt.target.value);
    }
    const handleChangeidExt= (evt) => {
        set_id_ext(evt.target.value);
    }
    const handleCreate = async () => {
        console.log(nombre_sucursal);
        const datos = {
            "nombre_sucursal" :nombre_sucursal,
            "provincia" : provincia.name,
            "municipio" : municipio.name,
            "direccion" : direccion,
            "cp" : cp,
            "telefono" : telefono,
            "email" : email,
            "tipo" : tipo,
            "estado" : 0
        }
        
        const response = await saveSucursal(datos);

        if (response.status == 201){
            SnackbarUtilities.success("Sucursal creada correctamente");
            setTimeout(()=>{
                window.location.replace('/sucursales');
            },3000)
        }else {
            SnackbarUtilities.error("ha habido un error, intente más tarde");
        }
        
    }
    const handleUpdate = async () => {
        const datos = {
            "nombre_sucursal" : nombre_sucursal,
            "provincia" : provincia.name,
            "municipio" : municipio.name,
            "direccion" : direccion,
            "cp" : cp,
            "telefono" : telefono,
            "email" : email,
            "tipo" : tipo
        }
        const response = await updateSucursal(id, datos);

        if (response.status == 201){
            SnackbarUtilities.success("La Sucursal ha sido guardada correctamente");
            setTimeout(()=>{
                window.location.replace('/sucursales');
            },3000);
        }else {
            SnackbarUtilities.error("ha habido un error, intente más tarde");
        }
    }
    
    return (
       <div className="container">
            {/* <BreadCrumb/> */}
            <div className="top-line">
                <div style={{textAlign: "start"}}>
                    <h3>{id ? 'Editar': 'Crear'} Sucursal</h3> 
                </div>
            </div>
            <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                <Button onClick={id ? handleUpdate : handleCreate} variant="outline-dark">
                    Guardar
                </Button>
            </div>
            <div className="middleline">
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <div className="row mb-3">
                        <div className="col-6 mb-3">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Control 
                                        type="nombre_sucursal"
                                        defaultValue={nombre_sucursal} 
                                        placeholder="Nombre Sucursal" 
                                        onChange={(e) => handleChangeNombreeSucursal(e)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-6 ">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Select
                                    
                                        name={'provincia'}
                                        value={provincia?.code}
                                        onChange={(e)=> handleChangeProvincias(e)}
                                    >
                                        <option value={''} >Selecciona Provincia</option>
                                        {provincias.map(pr => {
                                            return(<option value={pr.code}>{pr.name}</option>)
                                        })}
                                    </Form.Select>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Select
                                        
                                        value={municipio?.code}
                                        name={"municipio"}
                                        onChange={(e)=> handleChangeMunicipios(e)}
                                    >
                                        <option value={''} >Selecciona Município</option>
                                        {municipios.map(pr => {
                                            return(<option value={pr.code}>{pr.name}</option>)
                                        })}
                                    </Form.Select>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Control
                                        value={direccion}
                                        placeholder="Dirección" 
                                        name="direccion"
                                        onChange={(e)=> handleChangeDireccion(e)}    
                                    />
                                </InputGroup>
                            </div> 
                        </div> 
                        <div className="col-2">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Control
                                        value={cp}
                                        placeholder="CP" 
                                        name="cp"
                                        onChange={(e)=> handleChangeCP(e)}    
                                    />
                                </InputGroup>
                            </div> 
                        </div> 
                        <div className="col-3">
                            <div className="d-flex gap-2">
                                <PhoneInput
                                    inputProps={{
                                        name: 'telefono',
                                        id:"telefono",
                                    }}
                                    country={'es'}
                                    onlyCountries={['es']}
                                    value={telefono}
                                    onChange={(val,coun,ev,form) =>{ handleChangeTelefono (val,coun,ev,form)}}
                                />
                            </div> 
                        </div> 
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="d-flex gap-2">
                                <InputGroup>
                                    <Form.Control 
                                        type="email" 
                                        value={email} 
                                        placeholder="provincia@laehomes.com" 
                                        onChange={(e) => handleChangeEmail(e)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex gap-2">  
                                <InputGroup>
                                    <Form.Select
                                        value={tipo}
                                        name={"tipo"}
                                        onChange={(e)=> handleChangeTipo(e)}
                                    >
                                        <option value={''}>Selecciona Tipo</option>
                                        <option value={'central'}>Central</option>
                                        <option value={'businessCenter'}>Business Center</option>
                                        <option value={'oficinaCalle'}>Oficina</option>
                                        <option value={'Colaborador'}>Colaborador</option>
                                    </Form.Select>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex gap-2">  
                                <InputGroup>
                                    <Form.Control 
                                        type="text" 
                                        value={id_ext} 
                                        placeholder="id externo" 
                                        onChange={(e) => handleChangeidExt(e)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    );
}

export default FormSucursal