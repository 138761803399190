import * as React from 'react';
import { usePropiedades } from '../../hooks';
import { useEffect, useState } from 'react';
import { Drawer, Box, Typography, Checkbox, Button, TextField } from '@mui/material';
import DataTable from '../Tables/DataTable';
import SearchIcon from '@mui/icons-material/Search';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const DrawerPropiedades = ({ open, setOpen, setPropiedad, sucursal, propiedades = [], setPropedadesAux, calculaImmporte }) => {
    const { loading, data, fetchData } = usePropiedades();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({ "sucursal": sucursal?.id_ext || '' });
    
    const headers = [
        {
            numeric: false,
            disablePadding: false,
            label: "",
            overrideFunc: (data, row) => (
                <>
                    <Checkbox
                        sx={CheckboxStyles}
                        onChange={(e) => { asignarPropiedad(e, row) }}
                        checked={isCheckched(propiedades, row.id)}
                    />
                </>
            ),
        },
        {
            provincia: "referencia",
            numeric: false,
            disablePadding: false,
            label: "Referencia",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.referencia}
                </Typography>
            ),
        },
        {
            provincia: "provincia",
            numeric: false,
            disablePadding: false,
            label: "Provincia",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.provincia}
                </Typography>
            ),
        },
        {
            municipio: "municipio",
            numeric: false,
            disablePadding: false,
            label: "Municipio",
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.ciudad}
                </Typography>
            ),
        }
    ];

    const isCheckched = (propiedades, elId) => {
        return propiedades.some(prop => prop.id === elId || prop.id_prop === elId);
    }
    const asignarPropiedad = (e, prop) => {
        const { checked, value } = e.target;
        if (prop == '') {
            console.log("asdasdda")
            const newProp = {
                id: '',
                direccion: '',
                ciudad: '',
                cp: '',
                promocion: '',
                importe: 0
            };

            calculaImmporte(newProp, true);
            // setPropiedad(prevProps => {
            //     const newPropiedades = prevProps?.propiedades ? [...prevProps.propiedades, newProp] : [newProp];
            //     return { ...prevProps, propiedades: newPropiedades };
            // });
            setPropedadesAux(prevProps => {
                const currentPropiedades = prevProps || [];
                const newPropiedades = [...currentPropiedades, newProp];
                return newPropiedades;
            });
           
        } else {
           
            if (checked) {
               
                setPropedadesAux(prevProps => {
                    const currentPropiedades = prevProps || [];
                    const newPropiedades = [...currentPropiedades, prop];
                    return newPropiedades;
                });
                calculaImmporte(prop ,true);
            } else {
              
                setPropedadesAux(prevProps => {
                    const currentPropiedades = prevProps || [];
                    const newPropiedades = currentPropiedades.filter(p => p.id !== prop.id);
                    return newPropiedades;
                });
                calculaImmporte(prop ,false);
            }
        }
    };
  
    // const searchPropiedad = async( ) => {
    //     console.log(filtros);
    //     fetchData({filtros})
    // }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFiltros(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    useEffect(() => {
        if (sucursal?.id_ext) {
            setFiltros(prevState => ({
                ...prevState,
                sucursal: sucursal.id_ext
            }));
        }
    }, [sucursal]);
    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <div className='container'>
                <Box component="section" sx={{ p: 3 }}>
                    <h2>Propiedades</h2>
                    <p>Debes Seleccionar una propiedad</p>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <TextField
                            label="Referencia"
                            name="referencia"
                            size="small"
                            onChange={handleChange}
                            sx={{
                                ...inputStyles,
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#33383b',
                                },
                            }}
                        />
                        {/* <Button sx={styledButton} variant="outlined" onClick={() => searchPropiedad()} >
                            <SearchIcon />
                        </Button> */}

                    </Box>
                    <Button sx={styledButton} variant="outline-dark" onClick={(e) => { asignarPropiedad(e, '') }}>
                        añadir nueva propiedad
                    </Button>
                </Box>
            </div>
            <div className='middleline'>
                {sucursal.id_ext ?
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        fetcher={fetchData}
                        mensaje={"No hay propiedades disponibles"}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filtros={filtros}
                    // properties={properties}
                    />
                    :
                    <Typography>
                        Debes seleccionar una sucursal
                    </Typography>

                }

            </div>
        </Drawer>
    )
}

export default DrawerPropiedades;