import { useFormik } from 'formik';

// Función que encapsula la lógica del formulario
// Función para validar el formulario usando Yup
const validateForm = async (formData, validationSchema) => {
  try {
    // Validar el formulario con el esquema de Yup
    await validationSchema.validate(formData, { abortEarly: false });
    return {}; // No hay errores si la validación es exitosa
  } catch (error) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    console.log('Validation errors:', errors); // Log de errores de validación
    return errors;
  }
};

// Función que encapsula la lógica del formulario
export const useFormValidation = ({ initialValues, validationSchema, onSubmit }) => {

  const validate = async (values) => {
    const errors = await validateForm(values, validationSchema);
    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: async (values, { setSubmitting, setErrors, setStatus }) => {
      const errors = await validate(values);
      if (Object.keys(errors).length === 0) {
        try {
          console.log('Submitting values:', values); // Log de valores al enviar
          // setStatus({ success: 'Formulario enviado con éxito' }); // Seteamos un mensaje de éxito
          await onSubmit(values); // Llama a la función de envío del formulario
        } catch (error) {
          console.error('Form submission error:', error);
          setStatus({ error: 'Ocurrió un error al enviar el formulario' }); // Seteamos un mensaje de error
        }
      } else {
        setErrors(errors); // Actualiza los errores en Formik
        setStatus({ error: 'Existen errores en el formulario' }); // Seteamos un mensaje de error general
        console.log('Formik errors:', errors);
      }
      setSubmitting(false);
    },
  });

  return {
    handleChange: formik.handleChange,
    handleBlur: formik.handleBlur,
    handleSubmit: formik.handleSubmit,
    values: formik.values,
    errors: formik.errors,
    touched: formik.touched,
    setCustomFieldValue: formik.setFieldValue,
    setValues: formik.setValues,
    setValues: formik.setValues,
    handleReset: formik.handleReset,
    setStatus: formik.setStatus, // Devuelve el status del formulario
    status: formik.status, // Devuelve el status del formulario
  };
};
