import React, { useContext, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { SyncLoader } from "react-spinners";
import { deleteTrabajador, listTrabajadores } from "../../services";
import { DataTable } from "../../components";
import {
    Typography,
} from "@mui/material";
import { jsonRenameItems, transforHeader } from "../../utilities/transformHeaders";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarUtilities } from "../../utilities";
import { useNavigate, Link } from "react-router-dom";
import useTrabajadores from "../../hooks/useTrabajadores";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { listSucursales, listDepartamentos } from "../../services";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@mui/material/Modal';
import { useUser } from "../../hooks/UserContextLogin";
import ButtonPermission from "../../utilities/ButtonPermission";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "65%",
    height: "88%",
    bgcolor: 'background.paper',

    p: 4,
};

const Colaboradores = () => {
    const { data, fetchData, loading, limite } = useTrabajadores();
    const [currentPage, setCurrentPage] = useState(1);
    const { usuario } = useContext(UserContext); // Accede al contexto del usuario
    const [sucursalMap, setSucursalMap] = useState(null);
    const [departamentosList, setDepartamentosList] = useState(null);
    const [filtros, setFiltros] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { user, setUser } = useUser();

    const initialValues = {
        sucursal_id: '',
        departamento_id: '',
        buscador: '',
    };

    const validationSchema = Yup.object().shape({
        sucursal_id: Yup.string().nullable(),
        departamento_id: Yup.string().nullable(),
        buscador: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                console.log(values);
                if (values.sucursal_id || values.departamento_id || values.buscador) {
                    setFiltros(values);
                    // fetchData({ offset: currentPage, limit: 1000, filtros: { sucursal_id: values.sucursal_id } });
                    console.log(values);
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    // console.log(data);
    // console.log(data.rows?.length);
    const iconColumn = [{
        item: (name) => {
            return (
                <Typography
                    sx={{

                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    <div style={{ marginLeft: "30px" }}>{name?.Verificado ? <CheckIcon sx={{ color: "green", fontSize: 38 }} /> : <CloseIcon sx={{ color: "red", fontSize: 38 }} />}</div>
                </Typography>
            )
        },
        position: 8,
        titleHeader: "Verificado",
        nameColumn: "verified"
    }, {
        item: (name) => {
            return (
                <div style={{ display: "flex", justifyContent: "left" }}>
                    <Link to={`/trabajadores/form/${name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark" >
                        <EditIcon />
                    </Link>
                    <button className="btn btn-outline-dark" onClick={() => deleteItem(name.id)} style={{ marginLeft: "20px" }}>
                        <DeleteIcon />
                    </button>
                </div>
            )
        },
        position: 9,
        titleHeader: "Acciones",
        nameColumn: ""
    }]
    const navigate = useNavigate();

    const deleteItem = async (id) => {
        try {
            const response = await deleteTrabajador(id);
            console.log(response);
            if (response == 200) {
                SnackbarUtilities.success("Trabajador eliminado correctamente!");
                fetchData({ offset: currentPage, limit: 1000, filtros: { estado: 1 } });
                // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
            } else {
                SnackbarUtilities.error("Error al eliminar el trabajador. Inténtalo de nuevo más tarde.");
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = async () => {
        // window.open(window.location.origin + `/trabajadores/create`, "_top");
        navigate("/Trabajadores/form");
    }


    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Colaboradores</h3>
                </div>
                <div>
                    <ButtonPermission onClick={handleClick} variant="contained" endIcon={<NoteAddIcon />}
                        action="edit"
                        resource="Trabajadores"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Crear
                    </ButtonPermission>
                </div>
            </div>

            <DataTable
                data={{ rows: jsonRenameItems(data.rows, "Trabajadores", "titulos_tabla"), meta: data.meta }}
                fetcher={fetchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                mensaje={"No hay trabajadores disponibles"}
                isLoading={loading}
                // onClickRow={(row)=>console.log(row.id)}
                filtros={filtros || ''}
                headers={transforHeader(jsonRenameItems(data.rows, "Trabajadores", "titulos_tabla"), ["id", "DNI", "Estado", "Departamento", "Cargo", "sucursal_id", "Verificado", "user_trabajador", "fechaNac", "User ID", "status", "Sucursal", "Fecha de Nacimiento", "web", "url_profile_img",], iconColumn, "Trabajadores", "titulos_tabla", user)}
            />

        </div>
    );
}

export default Colaboradores;

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};