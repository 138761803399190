import { useState, useContext, useEffect } from "react";
import { listContactos } from "../services";
function useClientes() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data.map((row) => (
            {
                id: row.id,
                nombre: row.nombre,
                apellidos: row.apellidos,
                direccion: row.direccion,
                cp: row.cp,
                poblacion: row.poblacion,
                telefono: row.telefono,
                email: row.email,
                numero_identificacion: row.numero_identificacion,
            
            }));
    };

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;

        console.log(orderBy, order)
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `id,${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listContactos(payload);
            const {meta, data} = response;
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        loading,
        fetchData,
        limite
    };
};

export default useClientes;
